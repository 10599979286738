import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { apiUrl } from '../../config/api'
import { customSwal } from '../../config/swal'
import { IRole } from '../interfaces/role.interface'
import { IUser } from '../interfaces/user.interface'

interface IProps {
  id: string
  title: string
  type: 'action' | 'confirmation'
  positiveAnswer: string
  confirmationTitle?: string
  negativeAnswer?: string
  selectedValue?: IUser | IRole
  setChangedValue?: React.Dispatch<React.SetStateAction<any>>
  changedValue?: boolean
  archive?: boolean
  deleteUrl?: string
  updateStatusUrl?: string
}

export const ActionModal = ({
  id,
  title,
  negativeAnswer,
  positiveAnswer,
  selectedValue,
  changedValue,
  setChangedValue,
  archive,
  type,
  deleteUrl,
  updateStatusUrl,
}: IProps) => {
  const changeUserStatus = async () => {
    if (archive && deleteUrl) {
      return await apiUrl.delete(deleteUrl + selectedValue?.id)
    } else if (updateStatusUrl) {
      return await apiUrl.put(updateStatusUrl + selectedValue?.id)
    }
  }

  const { mutate: actionMutation, isLoading } = useMutation({
    mutationFn: changeUserStatus,
    onSuccess: (response) => {
      if (response) {
        customSwal.fire({
          icon: 'success',
          title: 'Se ha realizado correctamente',
        })
      }
      setChangedValue && setChangedValue(!changedValue)
    },
    onError: (error: AxiosError<{statusCode: number, message: string}>) => {
      if (error.response?.status === 400 && error.response?.data?.message?.includes('eliminar')) {
        customSwal.fire({
          icon: 'error',
          title: 'No se puede eliminar',
          text: error.response?.data?.message,
        })
        return
      }

      customSwal.fire({
        icon: 'error',
        title:
          error.response?.status === 403
            ? 'No tienes permisos para realizar esta acción'
            : 'Ha ocurrido un error',
        text: 'Intente nuevamente.',
      })
    },
  })

  return (
    <React.Fragment>
      <div className='modal fade p-5 rounded-4' tabIndex={-1} id={id} role='dialog'>
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body p-0 mb-5'>
              <div className='text-center my-8 p-0'>
                <img src={`${toAbsoluteUrl('/✅ Web - Mm/icons-56.png')}`} alt='e' />
              </div>
              <div className='fw-bolder'>
                <h1 className='text-alert text-center'>{title}</h1>
              </div>
            </div>

            <div className='text-center mx-5 row mb-5'>
              {type === 'action' ? (
                <React.Fragment>
                  <button
                    style={{ backgroundColor: '#E2F4FF', color: '#1C73CF' }}
                    type='button'
                    className='btn btn col m-3'
                    data-bs-dismiss='modal'
                  >
                    {negativeAnswer}
                  </button>
                  <button
                    disabled={isLoading}
                    style={{
                      background: '#0066CC',
                      color: 'white',
                    }}
                    type='button'
                    data-bs-dismiss={'modal'}
                    onClick={() => actionMutation()}
                    className='btn col m-3 fw'
                  >
                    {positiveAnswer}
                  </button>
                </React.Fragment>
              ) : (
                <button
                  style={{
                    background: '#0066CC',
                    color: 'white',
                  }}
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  type='button'
                  className='btn btn col m-3 fw'
                >
                  {positiveAnswer}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
