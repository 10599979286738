interface SearchInputProps {
  search: string
  setSearch: (state: string, cb?: ((state: string) => void) | undefined) => void
  placeholder?: string
}

const SearchInputWithDelete = ({ placeholder = 'Buscar', search, setSearch }: SearchInputProps) => {
  const handleClean = () => {
    setSearch('')
  }

  return (
    <div className='position-relative d-flex align-items-center'>
      <input
        className='form-control w-100 border-0 px-12'
        type='text'
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder={placeholder}
        style={{
          minWidth: '180px',
          backgroundColor: '#f9f9f9',
          height: '40px',
        }}
        name='searchName'
      />
      <i className='bi bi-search ps-4 position-absolute start-0 top-50 translate-middle-y' />
      {search.length > 0 && (
        <button
          type='button'
          className='border-0 bg-transparent pe-4 position-absolute end-0 top-50 translate-middle-y'
          onClick={handleClean}
          aria-label='Limpiar buscador'
        >
          <i className='bi bi-x-lg' />
        </button>
      )}
    </div>
  )
}

export default SearchInputWithDelete
