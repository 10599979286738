export const BackofficePermissionsEnum = {
  // ----- CLIENTES -----
  USER_CUSTOMER: {
    LIST: 'user_customer_list',
    SHOW: 'user_customer_show',
    IMPORT: 'user_customer_import',
    EXPORT: 'user_customer_export',
    UNLOCK: 'user_customer_unlock',
    UNLOCK_FACEPHI: 'user_customer_unlock_facephi',
    DELETE: 'user_customer_delete',
  },

  // ----- FONDOS ------
  FUND: {
    LIST: 'fund_list',
    SYNC: 'fund_sync',
    UPDATE: 'fund_update', // configuracion
    CHANGE_STATUS: 'fund_change_status',
    RECOMMENDED: 'fund_recommend',
  },

  // ----- MOVIMIENTOS -----
  MOVIMIENTOS_ALL: [] as string[],
  // Solicitudes pendientes
  REQUEST_PENDING: {
    LIST: 'request_pending_list',
    UPDATE: 'request_pending_update',
    CANCEL: 'request_pending_cancel',
    SPECTRUM: 'request_pending_spectrum',
    SHOW: 'request_pending_show',
  },

  // Suscripciones
  SUBSCRIPTION: {
    LIST: 'subscription_list',
    SHOW: 'subscription_show', // FIXED: no tiene detalle - Preguntar -> Amarrar al número de operación y adjuntos
  },

  // Solicitudes anuladas
  REQUEST_CANCELED: {
    LIST: 'request_canceled_list',
    SHOW: 'request_canceled_show', // no tiene detalle - Preguntar
  },

  // Rescates
  RESCUE: {
    LIST: 'rescue_list',
    CHANGE_STATUS: 'rescue_change_status',
  },

  // ----- CONFIGURACION -----
  CONFIG_ALL: [] as string[],
  // Usuarios
  USER_PERSON: {
    LIST: 'user_person_list',
    SHOW: 'user_person_show',
    CREATE: 'user_person_create',
    UPDATE: 'user_person_update',
    DELETE: 'user_person_delete', // no hay delete, preguntar
  },

  // Roles y permisos
  ROLE: {
    LIST: 'capability_role_list',
    SHOW: 'capability_role_show',
    CREATE: 'capability_role_create',
    UPDATE: 'capability_role_update',
    DELETE: 'capability_role_delete', // no hay delete, preguntar
  },

  // prospectos
  USER_PROSPECT: {
    LIST: 'user_prospect_list',
    SHOW: 'user_prospect_show', // preguntar no hay opción de detalle
    UPDATE: 'user_prospect_update', // hay una opción más de Generar contraseña, preguntar si se va a separar con otro permiso
  },

  // Tolerancia al riesgo
  RISK_TOLERANCE: {
    LIST: 'risk_tolerance_list', // NEW
    UPDATE: 'risk_tolerance_update', // FIXED: solo existe 1, se debería tener 2? uno para ver y otro para el botón Editar
  },

  // Lista asesoras
  USER_EXECUTIVE: {
    LIST: 'user_executive_list',
    SHOW: 'user_executive_show',
    SYNC: 'user_executive_sync',
    UPDATE: 'user_executive_update',
    ARCHIVE: 'user_executive_archive',
  },

  // Configuracion general
  GENERAL_CONFIG: {
    UPDATE: 'general_config_update', // falta consultar
  },

  // Audit
  AUDIT: {
    LIST: 'audit_list',
    SHOW: 'audit_show',
    EXPORT: 'audit_export',
  },

  // ----- LOGS -----
  LOGGER: {
    LIST: 'logger_list',
  },
}

BackofficePermissionsEnum.MOVIMIENTOS_ALL = [
  ...Object.values(BackofficePermissionsEnum.REQUEST_PENDING),
  ...Object.values(BackofficePermissionsEnum.SUBSCRIPTION),
  ...Object.values(BackofficePermissionsEnum.REQUEST_CANCELED),
  ...Object.values(BackofficePermissionsEnum.RESCUE),
]

BackofficePermissionsEnum.CONFIG_ALL = [
  ...Object.values(BackofficePermissionsEnum.USER_PERSON),
  ...Object.values(BackofficePermissionsEnum.ROLE),
  ...Object.values(BackofficePermissionsEnum.USER_PROSPECT),
  ...Object.values(BackofficePermissionsEnum.RISK_TOLERANCE),
  ...Object.values(BackofficePermissionsEnum.USER_EXECUTIVE),
  ...Object.values(BackofficePermissionsEnum.GENERAL_CONFIG),
  ...Object.values(BackofficePermissionsEnum.AUDIT),
]