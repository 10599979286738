import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { ListAudit } from '../../modules/config/audit/ListAudit'
import { SuspensedView } from '../PrivateRoutes'
import { ViewAudit } from '../../modules/config/audit/ViewAudit'

export const AuditRouting = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <RequireRole roles={Permissions.AUDIT.LIST}>
            <SuspensedView>
              <ListAudit />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='show/:id'
        element={
          <RequireRole roles={Permissions.AUDIT.SHOW}>
            <SuspensedView>
              <ViewAudit />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
