/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import usePermissions from '../../../common/hooks/usePermissions'
import { IUserShow } from '../../../common/interfaces/user.interface'
import { UserService } from '../../../services/UserService'

export const ViewUser = () => {
  const { id } = useParams()
  const { hasPermission } = usePermissions()

  const {
    isLoading,
    isError,
    isFetching,
    data: user,
  } = useQuery<IUserShow>(['user-show', id], () => UserService().showUser(id!))

  return (
    <React.Fragment>
      <div className='card user-card col' style={{ color: '#344054', marginTop: '10px' }}>
        {(!isLoading || !isFetching) && !isError ? (
          <React.Fragment>
            <div className='card-header m-5 col  border-0'>
              <div className='col mt-8  mb-0'>
                <span className='fw-bolder text-hover-primary fs-2'>
                  {user?.data.name} {user?.data.lastname}
                </span>
                {user?.data.status === 0 ? (
                  <span className='badge badge-light-danger m-5'>Inactivo</span>
                ) : user?.data.status === 1 ? (
                  <span className='badge badge-light-success m-5'>Activo</span>
                ) : (
                  <span className='badge badge-light-warning m-5'>Archivado</span>
                )}
              </div>
              {hasPermission(Permissions.USER_PERSON.UPDATE) && (
                <div className='col-3 text-end'>
                  <Link
                    to={`/user/edit/${id}`}
                    style={{
                      backgroundColor: '#0066CC',
                      color: 'white',
                      fontWeight: 600,
                    }}
                    className='btn m-5 pl-4'
                  >
                    <i style={{ color: 'white' }} className='bi bi-pencil m-0 p-1 fs-5'></i> Editar
                  </Link>
                </div>
              )}
            </div>
            <div className='card-body row mt-0 pb-0 m-5'>
              <h3 className='card-title general m-0 '>Datos Generales</h3>

              <div className='card-title pt-4 col-6 m-0 p-0'>
                <div className='mb-5 ml-1'>
                  <span className='fw-bolder text-hover-primary fs-4'>Celular</span>
                  <span className='text-muted text-muted d-block fs-5 mt-2'>
                    {user?.data.phone_number}
                  </span>
                </div>
              </div>
              <div className='card-title pt-4 col-6 m-0 p-0'>
                <div className='mb-5 ml-1'>
                  <span className='fw-bolder text-hover-primary fs-4'>Correo electrónico</span>
                  <span className='text-muted text-muted d-block fs-5 mt-2'>
                    {user?.data.email}
                  </span>
                </div>
              </div>
              <div className='card-title col-6 m-0 p-0'>
                <div className='mb-5 ml-1'>
                  <span className='fw-bolder text-hover-primary fs-4'>Roles</span>
                  {user?.data.roles?.length! > 0 ? (
                    <span className='text-muted text-muted d-block fs-5 mt-2'>
                      {user?.data.roles?.map((e) => e.name).join(', ')}
                    </span>
                  ) : (
                    <span className='text-muted text-muted d-block fs-5 mt-2'>
                      No hay roles asignados
                    </span>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status'></div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
