import { downloadTypes } from '../common/helpers/downloadHandlers'
import { apiUrl } from '../config/api'

export const AuditService = () => {
  const listAudit = (page: number | string, limit: number | string, search?: string, filters?: {}) =>
    apiUrl
      .get(`/audits/list`, { 
        params: { 
          page,
          limit,
          search,
          ...filters,
        } 
      })
      .then((res) => res.data)

  const showAudit = (id: string | string) =>
    apiUrl.get(`/audits/show/${id}`).then((res) => res.data)

  const exportAudit = (filters?: {}) =>
    apiUrl
      .get('/audits/export', {
        params: { ...filters },
        responseType: 'blob',
        headers: { Accept: downloadTypes.XLSX },
      })
      .then((res) => res.data)

  const exportAuditById = (id: string) =>
    apiUrl
      .get(`/audits/export/${id}`, {
        responseType: 'blob',
        headers: { Accept: downloadTypes.XLSX },
      })
      .then((res) => res.data)

  return {
    listAudit,
    showAudit,
    exportAudit,
    exportAuditById,
  }
}
