import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { GeneralConfig } from '../../modules/config/general/GeneralConfig'
import { AddForms } from '../../modules/config/riskForm/AddForms'
import { FormsList } from '../../modules/config/riskForm/FormsList'
import { SuspensedView } from '../PrivateRoutes'

export const ConfigGeneralRouting = () => {
  return (
    <Routes>
      <Route
        path='/general'
        element={
          <RequireRole roles={Permissions.GENERAL_CONFIG.UPDATE}>
            <SuspensedView>
              <GeneralConfig />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='/forms'
        element={
          <RequireRole roles={Permissions.RISK_TOLERANCE.LIST}>
            <SuspensedView>
              <FormsList />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='/forms/edit'
        element={
          <RequireRole roles={Permissions.RISK_TOLERANCE.UPDATE}>
            <SuspensedView>
              <AddForms />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
