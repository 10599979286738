/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { FundCard } from '../../common/components/cards/FundCard'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import usePermissions from '../../common/hooks/usePermissions'
import { useStateCallback } from '../../common/hooks/useStateCallback'
import { FundService } from '../../services/FundService'
import { useFunds } from './hooks/useFunds'

export const Funds = () => {
  const [selectedFund, setSelectedFund] = useStateCallback(0)
  const {
    data: funds,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['fund-show'], () => FundService().showFunds(), {
    keepPreviousData: true,
  })
  const { changeStatusFund, mutationSync } = useFunds({ refetch })
  const [modalId, setModalId] = useStateCallback('')
  const { hasPermission } = usePermissions()

  const setModal = (modal: string, currentFund: number) => {
    setModalId(modal)
    setSelectedFund(currentFund)
  }

  return (
    <React.Fragment>
      {/* se sincronaran los fondos con spectrum */}
      {hasPermission(Permissions.FUND.SYNC) && (
        <div className='w-full d-flex justify-content-end'>
          <button
            style={{ background: '#0066cc', color: 'white', marginRight: '2px' }}
            onClick={() => mutationSync.mutate()}
            className='btn mb-10 mr-3 text-end'
          >
            {mutationSync.isLoading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Sincronizando...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='d-flex justify-content-center align-items-center gap-3'>
                <i style={{ color: 'white' }} className='bi bi-arrow-clockwise fs-1'></i>
                Sincronizar
              </span>
            )}
          </button>
        </div>
      )}

      <div className='w-full'>
        {isLoading && isFetching ? (
          <div className='text-center m-20 p-20'>
            <div className='spinner-border' role='status'></div>
          </div>
        ) : (
          <div className='row gx-10'>
            {!funds ? (
              <span className='text-center m-20 ml-10 p-20'>No se encontró información</span>
            ) : (
              funds.length !== 0 &&
              funds
                .sort((a: any, b: any) => a.id - b.id)
                .map((fund: any, index: number) => {
                  if (!fund.structure) {
                    return (
                      <div key={index} className='col-lg-4 mb-8'>
                        <FundCard
                          status={fund.status}
                          className='card-xl-stretch mb-5 mb-xl-8'
                          codFund={fund.codFund}
                          title={fund.title}
                          id={fund.id}
                          level_risk={'none'}
                          annualized_return={''}
                          changeStatusFund={changeStatusFund}
                          cod_risk_profile={0}
                          msci_acwi_index={''}
                          effectiveness={''}
                          setModal={setModal}
                        />
                      </div>
                    )
                  }
                  const years = fund.structure.performance.data.sort(
                    (a: any, b: any) => b.year - a.year
                  )

                  const listTotal = years[0]

                  return (
                    <div key={index} className='col-lg-4 mb-8'>
                      <FundCard
                        status={fund.status}
                        className='card-xl-stretch mb-5 mb-xl-8'
                        codFund={fund.codFund}
                        title={fund.title}
                        id={fund.id}
                        level_risk={fund.structure.riskLevel.riskData}
                        annualized_return={listTotal?.total?.toString() ?? ''}
                        cod_risk_profile={0}
                        msci_acwi_index={''}
                        changeStatusFund={changeStatusFund}
                        effectiveness={''}
                        setModal={setModal}
                      />
                    </div>
                  )
                })
            )}
          </div>
        )}
      </div>
      <div className='modal fade p-5 rounded-4' tabIndex={-1} id='Modal' role='dialog'>
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header border-0 pb-0'>
              <button
                type='button'
                className='close border-0'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <img
                  src={`${toAbsoluteUrl('/✅ Web - Mm/Close.png')}`}
                  alt='Close icon'
                  aria-hidden='true'
                />
              </button>
            </div>

            <div className='modal-header  border-0 pb-0'></div>

            <div className='modal-body p-0 mb-5'>
              <div className='text-center mb-8 p-0'>
                <img src={`${toAbsoluteUrl('/✅ Web - Mm/icons-56.png')}`} alt='e' />
              </div>
              <div className='fw-bolder'>
                <h1 className='text-alert text-center'>
                  {modalId === 'Active'
                    ? 'Estás seguro que desas activar el fondo?'
                    : 'Estás seguro que desas inactivar el fondo?'}
                </h1>
              </div>
            </div>

            <div className='text-center mx-5 row mb-5'>
              <button
                style={{ backgroundColor: '#E2F4FF', color: '#1C73CF' }}
                type='button'
                className='btn btn col m-3'
                data-bs-dismiss='modal'
              >
                {'Cancelar'}
              </button>
              {modalId === 'Active' ? (
                <React.Fragment>
                  <button
                    style={{
                      background: '#0066CC',
                      color: 'white',
                    }}
                    type='button'
                    data-bs-toggle='modal'
                    onClick={() => changeStatusFund.mutate(selectedFund)}
                    className='btn col m-3 fw'
                  >
                    {'Si, activar'}
                  </button>
                </React.Fragment>
              ) : (
                <button
                  style={{
                    background: '#0066CC',
                    color: 'white',
                  }}
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  type='button'
                  onClick={() => changeStatusFund.mutate(selectedFund)}
                  className='btn btn col m-3 fw'
                >
                  {'Si, inactivar'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
