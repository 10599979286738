import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { Movements } from '../../modules/movements/Movements'
import { SuspensedView } from '../PrivateRoutes'

export const MovementsRouting = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <RequireRole
            roles={Permissions.CONFIG_ALL}
          >
            <SuspensedView>
              <Movements />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
