import React, { useEffect } from 'react'
import Tabs, { ITab } from '../../common/components/Tabs'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import usePermissions from '../../common/hooks/usePermissions'
import { AnnulledTab } from './components/tabs/AnnulledTab'
import { RescueTab } from './components/tabs/RescueTab'
import { SolicitudesTab } from './components/tabs/SolicitudesTab'
import { SuscriptionTab } from './components/tabs/SuscriptionTab'

const GenerateTabs = (): ITab[] => {
  const { hasPermission } = usePermissions()
  const generatedTabs = []

  if (hasPermission(Permissions.REQUEST_PENDING.LIST)) {
    generatedTabs.push({
      id: 'solicitudes',
      title: 'Suscripciones pendientes',
      icon: 'bi-journal',
      content: <SolicitudesTab />,
    })
  }

  if (hasPermission(Permissions.SUBSCRIPTION.LIST)) {
    generatedTabs.push({
      id: 'suscriptions',
      title: 'Suscripciones',
      icon: 'bi-journal-check',
      content: <SuscriptionTab />,
    })
  }

  if (hasPermission(Permissions.REQUEST_CANCELED.LIST)) {
    generatedTabs.push({
      id: 'solicitudes-annulled',
      title: 'Suscripciones anuladas',
      icon: 'bi-journal-x',
      content: <AnnulledTab />,
    })
  }

  if (hasPermission(Permissions.RESCUE.LIST)) {
    generatedTabs.push({
      id: 'rescues',
      title: 'Rescates',
      icon: 'bi-calendar-check',
      content: <RescueTab />,
    })
  }

  return generatedTabs
}

export const Movements = () => {
  useEffect(() => {
    $(function () {
      $('[data-bs-toggle="tooltip"]').tooltip()
    })
  }, [])

  return (
    <React.Fragment>
      <Tabs tabs={GenerateTabs()} />
    </React.Fragment>
  )
}
