/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { BackofficePermissionsEnum as Permissions } from '../../../../../common/enums/backOfficePermissionsEnum'
import usePermissions from '../../../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../../../common/hooks/useStateCallback'
import { apiUrl } from '../../../../../config/api'
import { ExecutiveTable } from './ExecutiveTable'

export const BusinessExecutive = () => {
  const [search, setSearch] = useStateCallback('')
  const [selectedUser, setSelectedUser] = useStateCallback({})
  const [changedUser, setChangedUser] = useStateCallback(false)
  const { hasPermission } = usePermissions()

  const syncBusiness = () => {
    apiUrl.post('business-executive/sync-executives').then((res) => {
      setChangedUser(true)
    })
  }

  return (
    <React.Fragment>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0'>
          <div className='text-center mt-8'>
            <input
              className='form-control'
              type='text'
              placeholder='🔍︎  Buscador'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              style={{
                width: '100%',
                minWidth: '320px',
                backgroundColor: '#f9f9f9',
                border: 0,
                height: '40px',
              }}
              name='searchName'
            />
          </div>
          {hasPermission(Permissions.USER_EXECUTIVE.SYNC) && (
            <div className=' col text-end mt-4'>
              <button
                style={{ background: 'rgb(0, 123, 195)' }}
                className='btn text-white cursor-pointer'
                onClick={() => syncBusiness()}
              >
                <i
                  style={{ color: 'white', marginRight: '5px' }}
                  className='bi bi-arrow-clockwise  fs-1'
                ></i>
                Sincronizar ejecutivos
              </button>
            </div>
          )}
        </div>
        <ExecutiveTable
          setSelectedUser={setSelectedUser}
          changedUser={changedUser}
          setSearch={setSearch}
          search={search}
        />
      </div>
    </React.Fragment>
  )
}
