/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'
import { dateRangePickerDefault } from '../../../../common/helpers/DateHandlers'
import {
  AuditEntityEnum,
  AuditEventTypeEnum,
  AuditStatusEnum,
  IAuditFilter,
} from '../../../../common/interfaces'

interface IProps {
  filters: IAuditFilter
  setFilters: (state: IAuditFilter, cb?: ((state: IAuditFilter) => void) | undefined) => void
}

export const FilterAudit = ({ filters, setFilters }: IProps) => {
  const [localFilters, setLocalFilters] = useState<IAuditFilter>({})

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setLocalFilters((prev) => ({ ...prev, [name]: value }))
  }

  const handleConfirmar = () => {
    setFilters(localFilters)
  }

  useEffect(() => {
    setLocalFilters(filters)
    if (filters.created_at_start === undefined && filters.created_at_end === undefined) {
      $('input[name="daterange"]').trigger('cancel.daterangepicker')
    }
  }, [JSON.stringify(filters)])

  useEffect(() => {
    //@ts-ignore
    $(function () {
      //@ts-ignore
      $('input[name="daterange"]').daterangepicker({ ...dateRangePickerDefault, drops: 'up' })
      $('input[name="daterange"]').on('apply.daterangepicker', function (_, picker) {
        $(this).val(
          picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
        )
        setLocalFilters((prevState) => ({
          ...prevState,
          created_at_start: moment(picker.startDate).format('YYYY-MM-DD'),
          created_at_end: moment(picker.endDate).format('YYYY-MM-DD'),
        }))
      })

      $('input[name="daterange"]').on('cancel.daterangepicker', function () {
        $(this).val('')
        $('input[name="daterange"]').data('daterangepicker').setStartDate(moment())
        $('input[name="daterange"]').data('daterangepicker').setEndDate(moment())
        setLocalFilters((prevState) => {
          const { created_at_start, created_at_end, ...prev } = prevState
          return { ...prev }
        })
      })
    })
  }, [])

  return (
    <div
      className='modal modal_outer right_modal fade scroll'
      id='filter-audit'
      role='dialog'
      aria-labelledby='modalAuditLabelled'
    >
      <div className='modal-dialog w-75 w-sm-50 w-md-25' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='text-alert mb-0'>Filtros</h2>
            <i className='bi bi-x-lg fs-5 cursor-pointer' data-bs-dismiss='modal' />
          </div>
          <div className='modal-body d-flex flex-column gap-10'>
            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>ID de entidad</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Ingrese id de entidad'
                name='entity_id'
                value={localFilters.entity_id || ''}
                onChange={handleChange}
              />
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Tipo de evento</label>
              <select
                className='form-select form-select-solid'
                defaultValue=''
                name='event_type'
                value={localFilters.event_type || ''}
                onChange={handleChange}
              >
                <option value=''>Seleccionar</option>
                {Object.values(AuditEventTypeEnum).map((eventType) => (
                  <option key={eventType} value={eventType}>
                    {eventType}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Entidad</label>
              <select
                className='form-select form-select-solid'
                defaultValue=''
                name='entity'
                value={localFilters.entity || ''}
                onChange={handleChange}
              >
                <option value=''>Seleccionar</option>
                {Object.values(AuditEntityEnum).map((entity) => (
                  <option key={entity} value={entity}>
                    {entity}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Usuario</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Ingrese usuario'
                name='user'
                value={localFilters.user ?? ''}
                onChange={handleChange}
              />
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Fecha del evento</label>
              <input
                type='text'
                className='form-control form-control-solid'
                name='daterange'
                placeholder='Selecciona una fecha'
                style={{ zIndex: 99999 }}
                autoComplete='off'
              />
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Resultado</label>
              <select
                className='form-select form-select-solid'
                defaultValue=''
                name='status'
                value={localFilters.status || ''}
                onChange={handleChange}
              >
                <option value=''>seleccionar</option>
                <option value={AuditStatusEnum.SUCCESS}>Exitosa</option>
                <option value={AuditStatusEnum.FAILED}>Fallida</option>
              </select>
            </div>
          </div>
          <div className='modal-footer py-2'>
            <div className='text-end'>
              <button
                style={{ backgroundColor: '#eee', color: '#969696' }}
                type='button'
                className='btn col m-3'
                data-bs-dismiss='modal'
              >
                Cancelar
              </button>
              <button
                style={{
                  background: 'rgb(0, 123, 195)',
                  color: 'white',
                }}
                className='btn col m-3'
                data-bs-dismiss='modal'
                aria-label='Close'
                type='button'
                onClick={handleConfirmar}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
