/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ChangeEvent, useEffect, useState } from 'react'
import { BackofficePermissionsEnum as Permissions } from '../../../../../common/enums/backOfficePermissionsEnum'
import { usePagination } from '../../../../../common/hooks/usePagination'
import usePermissions from '../../../../../common/hooks/usePermissions'
import { IExecutive, IExecutiveList } from '../../../../../common/interfaces/user.interface'
import { apiUrl } from '../../../../../config/api'
import { customSwal } from '../../../../../config/swal'

interface IProps {
  search: string
  setSelectedUser: React.Dispatch<React.SetStateAction<any>>
  changedUser: boolean
  setSearch: any
}
interface UserData {
  id: number
  name: string
  lastname: string
  email: string
  status: number
  is_principal: boolean
}
const pageSizes = [10, 20, 40, 100]

export const ExecutiveTable = ({ search, setSelectedUser, changedUser, setSearch }: IProps) => {
  const {
    data: users,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useQuery<IExecutiveList>(['bussiness-executive-list'], () => listUsers(), {
    keepPreviousData: true,
  })
  const { page, limit, setPage, setLimit, paginationComponent } = usePagination({
    initPage: 1,
    initLimit: 10,
    list: users,
    pageSizes,
  })

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, changedUser, refetch])

  useEffect(() => {
    refetch()
  }, [page, search])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('', () => {
      refetch()
    })
  }

  const listUsers = () =>
    apiUrl
      .post(`/business-executive/list?page=${page}&limit=${limit}`, `&search=${search}`)
      .then((res) => res.data)
      .catch((error) => {
        const axiosError = error as AxiosError<{ message?: string }>
        customSwal.fire({
          title:
            axiosError.response?.status === 403
              ? 'No tienes permisos para realizar esta acción'
              : 'Error al cargar listado de ejecutivos',
          text: 'Intente nuevamente.',
          icon: 'error',
        })
      })

  const showUser = async (id: number) => {
    return apiUrl.get(`/business-executive/show/${id}`).then((response) => {
      setUserData(response.data.data)
      return response.data
    })
  }
  const changeStatus = async (id: number, status: any) => {
    customSwal
      .fire({
        title: `Está seguro que quieres ${status} el asesor?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Si, ${status} asesor`,
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result.isConfirmed) {
          apiUrl
            .put(`/business-executive/change-status/${id}`)
            .then((response) => {
              customSwal.fire({
                title: 'Se ha realizado el cambio de estado',
                icon: 'success',
              })
              setTimeout(() => {
                window.location.reload()
              }, 2000)
            })
            .catch((error) => {
              const axiosError = error as AxiosError<{ message?: string }>
              customSwal.fire({
                title:
                  axiosError.response?.status === 403
                    ? 'No tienes permisos para realizar esta acción'
                    : 'Ha ocurrido un error',
                text: 'Intente nuevamente.',
                icon: 'error',
              })
            })
        }
      })
  }
  const archiveExecutive = async (id: number) => {
    customSwal
      .fire({
        title: `Está seguro que quieres archivar el asesor?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Si, archivar asesor`,
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result.isConfirmed) {
          apiUrl
            .delete(`/business-executive/delete/${id}`)
            .then((response) => {
              customSwal.fire({
                title: 'Se ha archivado el asesor.',
                icon: 'success',
              })
              setTimeout(() => {
                window.location.reload()
              }, 2000)
            })
            .catch((error) => {
              const axiosError = error as AxiosError<{ message?: string }>
              customSwal.fire({
                title:
                  axiosError.response?.status === 403
                    ? 'No tienes permisos para realizar esta acción'
                    : 'Ha ocurrido un error',
                text: 'Intente nuevamente.',
                icon: 'error',
              })
            })
        }
      })
  }
  const [userData, setUserData] = useState<UserData | null>(null)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editedData, setEditedData] = useState<Partial<UserData>>({})
  const { hasPermission } = usePermissions()

  const handleEditClick = () => {
    setEditMode(true)
    setEditedData({
      name: userData?.name,
      lastname: userData?.lastname,
      email: userData?.email,
    })
  }
  const handleCancelEdit = () => {
    setEditMode(false)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedData({
      ...editedData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSaveClick = async (id: any) => {
    try {
      await apiUrl.patch(`/business-executive/update/${id}`, {
        name: editedData.name || userData!.name,
        lastname: editedData.lastname || userData!.lastname,
        email: editedData.email || userData!.email,
      })
      customSwal.fire({
        title: 'Se ha editado correctamente',
        icon: 'success',
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error: any) {
      customSwal.fire({
        title:
          error.response.status === 403
            ? 'No tienes permisos para realizar esta acción'
            : 'Error al guardar los cambios',
        icon: 'error',
      })
    }
  }

  const showUserMutation = useMutation({
    mutationFn: (id: number) => showUser(id),
  })
  const assignBussinessMutation = useMutation({
    mutationFn: (businessId: number) => assignBussinessMain(businessId),
    onSuccess: (res) => {
      showUserMutation.mutate(userData?.id!)
      refetch()
    },
  })

  const assignBussinessMain = (businessId: number) =>
    apiUrl.post(`/business-executive/assign_main/${businessId}`).then((res: any) => res.data)

  return (
    <div className='card-body'>
      <div className='table-responsive'>
        {(isLoading || isFetching) && !isError ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status'></div>
          </div>
        ) : (
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                {hasPermission([
                  Permissions.USER_EXECUTIVE.SHOW,
                  Permissions.USER_EXECUTIVE.UPDATE,
                  Permissions.USER_EXECUTIVE.ARCHIVE,
                ]) && <th className='w-25px'>ACCIONES</th>}
                <th className='min-w-150px'>NOMBRES </th>
                <th className='min-w-120px'>APELLIDOS</th>
                <th className='min-w-150px'>CORREO</th>
                <th className='min-w-100px'>ESTADO</th>
              </tr>
            </thead>

            <tbody>
              {users?.data.map((user: IExecutive, index: number) => {
                return (
                  <tr key={index}>
                    {hasPermission([
                      Permissions.USER_EXECUTIVE.SHOW,
                      Permissions.USER_EXECUTIVE.UPDATE,
                      Permissions.USER_EXECUTIVE.ARCHIVE,
                    ]) && (
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <div className='dropdown'>
                            <button
                              className='btn btn-light'
                              type='button'
                              id='dropdownMenuButton'
                              data-bs-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <i className='fas p-0 fa-ellipsis-v'></i>
                            </button>
                            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                              <div>
                                {hasPermission(Permissions.USER_EXECUTIVE.SHOW) && (
                                  <button
                                    className='dropdown-item'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ViewExecutive'
                                    onClick={() => showUserMutation.mutate(user.id)}
                                  >
                                    {' '}
                                    <i className='bi bi-eye m-2'></i> Ver detalle
                                  </button>
                                )}
                                {hasPermission(Permissions.USER_EXECUTIVE.UPDATE) && (
                                  <>
                                    {user.status !== -1 ? (
                                      <button
                                        className='dropdown-item'
                                        onClick={() =>
                                          changeStatus(
                                            user.id,
                                            user.status === 1 ? 'inactivar' : 'activar'
                                          )
                                        }
                                      >
                                        {' '}
                                        {user.status === 1 ? (
                                          <span>
                                            <i className='bi bi-lock m-2'></i> Inactivar
                                          </span>
                                        ) : user.status === 0 ? (
                                          <span>
                                            <i className='bi bi-unlock m-2'></i> Activar
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </button>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}
                                {hasPermission(Permissions.USER_EXECUTIVE.ARCHIVE) && (
                                  <>
                                    {user.status !== -1 && (
                                      <button
                                        type='button'
                                        // data-bs-toggle='modal'
                                        // data-bs-target='#Archive'
                                        className='dropdown-item'
                                        onClick={() => archiveExecutive(user.id)}
                                      >
                                        <i className='bi bi-archive m-2'></i>
                                        Archivar
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    )}
                    <td>
                      <span className='text-dark fs-6'>{user.name}</span>
                      {user.is_principal && (
                        <span style={{ marginLeft: 10 }} className='badge badge-light-primary'>
                          Principal
                        </span>
                      )}
                    </td>
                    <td>
                      <span className='text-dark text-hover-primary fs-6'>
                        {user.lastname || '--'}
                      </span>
                    </td>

                    <td>
                      <span className='text-muted d-block fs-5'>{user.email || '--'}</span>
                    </td>
                    <td>
                      {user.status === 0 ? (
                        <span className='badge badge-light-danger'>Inactivo</span>
                      ) : user.status === 1 ? (
                        <span className='badge badge-light-success'>Activo</span>
                      ) : (
                        <span className='badge badge-light-warning'>Archivado</span>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {isError && (
          <span className='notFound text-muted text-muted fs-5 text-center mt-5'>
            No se encontraron resultados
            <a
              className='link'
              style={{
                marginLeft: 5,
                cursor: 'pointer',
              }}
              onClick={cleanupRequest}
            >
              refrescar la tabla
            </a>
          </span>
        )}
        {users?.data.length === 0 && !isLoading && (
          <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
            No se encontraron resultados
            <a
              className='link'
              style={{
                marginLeft: 5,
                cursor: 'pointer',
              }}
              onClick={cleanupRequest}
            >
              mostrar todos los usuarios
            </a>
          </span>
        )}
        <div className='modal fade p-10' tabIndex={-1} id='ViewExecutive'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content p-5 pt-0'>
              <div className='modal-header'>
                <h3 className='modal-title'>Información del asesor</h3>

                <i
                  className='btn bi bi-x-lg btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={handleCancelEdit}
                  aria-label='Close'
                ></i>
              </div>

              <div className='modal-body'>
                <div className='d-flex flex-column gap-5'>
                  {!editMode ? (
                    <div>
                      <p>
                        <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>
                          Nombres:
                        </label>{' '}
                        {userData?.name}
                      </p>
                      <p>
                        <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>
                          Apelidos:
                        </label>{' '}
                        {userData?.lastname || '--'}
                      </p>
                      <p>
                        <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>
                          Email:
                        </label>{' '}
                        {userData?.email || '--'}
                      </p>

                      <div className='card-footer text-end m-0 p-0'>
                        <button
                          className='btn m-2'
                          style={{
                            backgroundColor: '#f9f9f9',
                            color: '#a1a5be',
                            marginLeft: '5px',
                          }}
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          Cancelar
                        </button>
                        {userData?.status !== -1 ? (
                          <button
                            className='btn m-2'
                            style={{ background: 'rgb(0, 123, 195)', color: 'white' }}
                            onClick={handleEditClick}
                          >
                            Editar
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    <form
                      className='d-flex flex-column gap-5'
                      onSubmit={(evt) => {
                        evt.preventDefault()
                        handleSaveClick(userData?.id)
                      }}
                    >
                      <div>
                        <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>
                          Nombres:
                        </label>
                        <input
                          className='form-control'
                          type='text'
                          name='name'
                          value={editedData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>
                          Apellidos:
                        </label>
                        <input
                          className='form-control'
                          type='text'
                          name='lastname'
                          value={editedData.lastname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>
                          Email:
                        </label>
                        <input
                          className='form-control'
                          type='email'
                          name='email'
                          value={editedData.email}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className='d-flex gap-4 mt-3 form-check form-check-custom form-check-solid'>
                        <input
                          style={{
                            width: '24px',
                            height: '24px',
                            cursor: 'pointer',
                            zIndex: 9999,
                          }}
                          className='form-check-input'
                          checked={userData?.is_principal}
                          onChange={() => assignBussinessMutation.mutate(userData?.id!)}
                          type='checkbox'
                          id='flexCheckDefault'
                        />
                        <label htmlFor='flexRadioLg'>Asesor por defecto</label>
                      </div>
                      <div className='card-footer text-end m-0 p-0'>
                        <button
                          className='btn m-2'
                          style={{
                            backgroundColor: '#f9f9f9',
                            color: '#a1a5be',
                            marginLeft: '5px',
                          }}
                          onClick={handleCancelEdit}
                        >
                          Cancelar
                        </button>
                        <button
                          type='submit'
                          className='btn m-2'
                          style={{ background: 'rgb(0, 123, 195)', color: 'white' }}
                        >
                          Guardar
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {paginationComponent()}
    </div>
  )
}
