import { useMutation } from '@tanstack/react-query'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { IPaymentStatusEnum, ISelectedRescue, RescueStatusEnum } from '../../../common/interfaces'
import { customSwal } from '../../../config/swal'
import { RescueService } from '../../../services/RescueService'
import { IFilterStatus } from '../components/tabs/RescueTab'

export const useRescues = () => {
  const [selectedRescue, setSelectedRescue] = useStateCallback<number>(0)
  // ** Ids of the selected rescues, if the rescue has "In progress" status, we should send the "details_id"
  const [selectedRescues, setSelectedRescues] = useStateCallback<ISelectedRescue[]>([])
  const [selectedStatus, setSelectedStatus] = useStateCallback<null | IPaymentStatusEnum>(null)
  const [comment, setComment] = useStateCallback<string>('')

  const getSelectedRescuesIds = () => {
    return selectedRescues.map((rescue) => rescue.id) as number[]
  }

  const changeStatus = (status: IFilterStatus) => {
    const selectedRescuesIds = getSelectedRescuesIds()
    const firstRescueStatus = selectedRescues[0].status;

    if (firstRescueStatus === RescueStatusEnum.PENDING) {
      if (selectedStatus === IPaymentStatusEnum.ANNULLED) {
        return RescueService().changeStatusRescue(status, selectedRescuesIds, comment);
      } else {
        return RescueService().changeStatusSendSpectrumRescue(selectedRescuesIds);
      }
    } else {
      return RescueService().changeStatusRescue(status, selectedRescuesIds, comment);
    }
  }

  const changeStatusMutation = useMutation({
    mutationFn: changeStatus,
    onSuccess: (data) => {
      if (data.errors?.length) {
        customSwal.fire({
          icon: 'error',
          title: 'Error al actualizar el estado del rescate.', 
          text: data.errors.map((o: { message: string }) => o.message).join('. '),
        })
        return
      }

      customSwal.fire({
        icon: 'success',
        title: `Estado actualizado para ${selectedRescues.length} ${
          selectedRescues.length > 1 ? 'rescates' : 'rescate'
        }.`,
      })
      setSelectedRescues([])
    },
    onError: () => {
      customSwal.fire({
        icon: 'error',
        title: 'Error al actualizar el estado del rescate.',
      })
    },
  })

  const previewImage = async (voucher: any, elementId: string) => {
    const res = await fetch(voucher)
    const blob = await res.blob()

    const img = new Image()
    img.src = URL.createObjectURL(blob)

    img.width = 400
    img.height = 800
    document.getElementById(elementId)!.append(img)
    await img.decode()

    URL.revokeObjectURL(img.src)
  }

  return {
    changeStatusMutation,
    comment,
    setComment,
    selectedRescue,
    setSelectedRescue,
    selectedRescues,
    setSelectedRescues,
    selectedStatus,
    setSelectedStatus,
    previewImage,
  }
}
