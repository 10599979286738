/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { formatDateTimeUTC, formatTimeToHHMMSS } from '../../../../common/helpers/DateHandlers'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import usePermissions from '../../../../common/hooks/usePermissions'
import { AuditStatusEnum, IAudit, IAuditFilter } from '../../../../common/interfaces'
import { AuditService } from '../../../../services'

const pageSizes = [10, 20, 50, 100]

type Props = {
  search: string
  setSearch: (state: string, cb?: ((state: string) => void) | undefined) => void
  filters: IAuditFilter
  setFilters?: (state: IAuditFilter, cb?: ((state: IAuditFilter) => void) | undefined) => void
}

export const AuditTable = ({ search, setSearch, filters, setFilters }: Props) => {
  const { hasPermission } = usePermissions()
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: { data: audits, isLoading, isFetching, refetch, isError },
  } = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(0)!,
    paginatorOptions: {
      queryKey: ['audit-list'],
      fetch: AuditService().listAudit,
    },
    search,
    pageSizes,
    filters,
  })

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('')
    setFilters && setFilters({}, () => refetch())
  }

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), limit, refetch])

  if (isError) {
    return (
      <div className='card notFound flex-column align-items-center'>
        <span className='text-muted fs-5'>No se encontraron resultados</span>
        <a className='link cursor-pointer fs-5' onClick={() => refetch()}>
          volver a cargar
        </a>
      </div>
    )
  }

  return (
    <div className='card-body'>
      <div className='table-responsive p-2'>
        {(isLoading || isFetching) && !isError ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status' />
          </div>
        ) : (
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                {hasPermission(Permissions.AUDIT.SHOW) && (
                  <th className='min-w-80px text-center'>ACCIONES</th>
                )}
                {/* <th className='min-w-150px'>ID DE ENTIDAD</th> */}
                <th className='min-w-100px'>TIPO DE EVENTO</th>
                <th className='min-w-100px'>ENTIDAD</th>
                <th className='min-w-200px'>USUARIO</th>
                <th className='min-w-150px'>FECHA Y HORA</th>
                <th className='min-w-100px text-center'>RESULTADO</th>
              </tr>
            </thead>
            <tbody>
              {audits?.data.map((audit: IAudit) => (
                <tr key={audit.id}>
                  {hasPermission(Permissions.AUDIT.SHOW) && (
                    <td className='text-center'>
                      <Link to={`/audit/show/${audit.id}`}>
                        <i className='bi bi-eye m-2 fs-3' />
                      </Link>
                    </td>
                  )}
                  {/* <td className='text-muted'>{audit.entity_id || '--'}</td> */}
                  <td className='text-muted'>{audit.event_type}</td>
                  <td className='text-muted'>{audit.entity}</td>
                  <td className='text-muted'>{audit.user}</td>
                  <td className='text-muted'>
                    {audit.updated_at ? (
                      <div className='d-flex flex-column'>
                        <span>{formatDateTimeUTC(new Date(audit.created_at).toDateString())}</span>
                        <span>{formatTimeToHHMMSS(new Date(audit.created_at))}</span>
                      </div>
                    ) : (
                      '--'
                    )}
                  </td>
                  <td className='text-muted text-center'>
                    {audit.status === AuditStatusEnum.SUCCESS ? (
                      <span className='badge badge-light-success px-4 py-2'>Exitosa</span>
                    ) : audit.status === AuditStatusEnum.FAILED ? (
                      <span className='badge badge-light-danger px-4 py-2'>Fallida</span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {(isError || (audits?.data?.length === 0 && !isLoading)) && (
          <span className='notFound text-muted fs-5 text-center d-flex flex-column m-8'>
            No se encontraron resultados
            <a className='link ml-2 cursor-pointer' onClick={cleanupRequest}>
              refrescar la tabla
            </a>
          </span>
        )}
      </div>
      {paginationComponent()}
    </div>
  )
}
