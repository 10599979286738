import clsx from 'clsx'
import React from 'react'

interface IProps {
  page?: number | '...'
  currentPage: number | undefined
  setPage: (state: number, cb?: ((state: number) => void) | undefined) => void
}

export const PaginationItem = ({ page, currentPage, setPage }: IProps) => {
  const active = currentPage?.toString() === page?.toString() ? 'active' : ''
  return (
    <React.Fragment>
      <li
        onClick={() => typeof page !== 'string' && setPage(page!)}
        className={clsx('page-item', active)}
      >
        <div style={{ cursor: 'pointer' }} className='page-link'>
          {page}
        </div>
      </li>
    </React.Fragment>
  )
}
