/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { ICustomerDetail } from '../../../../common/interfaces'
import { apiUrl } from '../../../../config/api'
import { customSwal } from '../../../../config/swal'
import { FileService } from '../../../../services/FileService'
import { SpectrumDocumentType } from '../../ViewClient'
import { BankPersonTable } from '../tables/BankPersonTable'
import { ContractDocumentTable } from '../tables/ContractsDocumentTable'
import { NaturalPersonTable } from '../tables/NaturalPersonTable'
import { SpousePersonTable } from '../tables/SpousePersonTable'

const editCustomerInfoSchema = Yup.object().shape({
  phone_number: Yup.string().trim().required('Número de teléfono es requerido.'),
  gender: Yup.string().trim().required('Sexo es requerido.'),
  civil_status: Yup.string().trim().required('Estado civil es requerido.'),
  instruction_grade: Yup.number().required('Grado de instrucción es requerido.'),
  nationality: Yup.string().trim().required('Nacionalidad es requerido.'),
  type_document: Yup.string().trim().required('Tipo de documento es requerido.'),
  number_document: Yup.string().trim().required('Número de documento es requerido.'),
  type_worker: Yup.string().trim().required('Tipo de trabajador es requerido.'),
  occupation: Yup.string().trim().required('Ocupación es requerido.'),
  position: Yup.string().trim().required('Posición es requerida.'),
  entry_date: Yup.number().required('Antigüedad es requerida.').min(1, 'Antigüedad es requerida.'),
  business_name: Yup.string().trim().required('Razón social es requerida.'),
  address: Yup.string().trim().required('Razón social es requerida.'),
  urb: Yup.string().trim('No debe tener espacios en blanco.').nullable(),
  dpto: Yup.string().trim('No debe tener espacios en blanco.').nullable(),
  name: Yup.string().trim().required('Primer nombre es requerido.'),
  middlename: Yup.string().trim().nullable(),
  surname: Yup.string().trim().required('Primer apellido es requerido.'),
  lastname: Yup.string().trim().required('Segundo apellido es requerido.'),
  spouse_name: Yup.string().trim().required('Primer nombre del cónyuge es requerido.'),
  spouse_middlename: Yup.string().trim('No debe tener espacios en blanco.').nullable(),
  spouse_surname: Yup.string().trim('No debe tener espacios en blanco.').nullable(),
  spouse_lastname: Yup.string().trim().required('Segundo apellido del cónyuge es requerido.'),
  regime_type: Yup.string().trim().required('Tipo de régimen es requerido.'),
  spouse_type_document: Yup.string().trim().required('Tipo de documento del cónyuge es requerido.'),
  spouse_number_document: Yup.string()
    .trim()
    .required('Número de documento del cónyuge es requerida.'),
  spouse_nationality: Yup.string().trim().required('Nacionalidad del cónyuge es requerida.'),
})
const editCustomerInfoSchemaSingle = Yup.object().shape({
  phone_number: Yup.string().trim().required('Número de teléfono es requerido.'),
  gender: Yup.string().trim().required('Sexo es requerido.'),
  civil_status: Yup.string().trim().required('Estado civil es requerido.'),
  instruction_grade: Yup.number().required('Grado de instrucción es requerido.'),
  nationality: Yup.string().trim().required('Nacionalidad es requerido.'),
  type_document: Yup.string().trim().required('Tipo de documento es requerido.'),
  number_document: Yup.string().trim().required('Número de documento es requerido.'),
  type_worker: Yup.string().trim().required('Tipo de trabajador es requerido.'),
  occupation: Yup.string().trim().required('Ocupación es requerido.'),
  position: Yup.string().trim().required('Posición es requerida.'),
  entry_date: Yup.number().required('Antigüedad es requerida.'),
  business_name: Yup.string().trim().required('Razón social es requerida.'),
  address: Yup.string().trim().required('Razón social es requerida.'),
  urb: Yup.string().trim('No debe tener espacios en blanco.').nullable(),
  dpto: Yup.string().trim('No debe tener espacios en blanco.').nullable(),
  name: Yup.string().trim().required('Primer nombre es requerido.'),
  middlename: Yup.string().trim().nullable(),
  surname: Yup.string().trim().required('Primer apellido es requerido.'),
  lastname: Yup.string().trim().required('Segundo apellido es requerido.'),
})

interface IProps {
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  customerDetail: ICustomerDetail | undefined
}

function getFileNameFromBucketPath(path: string) {
  return path.substring(path.lastIndexOf('/') + 1, path.length)
}

export const InfoClientTab: React.FC<IProps> = ({
  customerDetail,
  isError,
  isFetching,
  isLoading,
}) => {
  const [isLoadingDownload, setIsLoading] = useStateCallback(false)
  const [fileUrl, setFileUrl] = useStateCallback('')

  const downloadMutation = useMutation({
    mutationFn: (fileUrl2: string) => FileService().downloadFromAzure(fileUrl2),
    onSuccess: async (data) => {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', getFileNameFromBucketPath(fileUrl))

      document.body.appendChild(link)
      link.click()

      await new Promise((resolve) => setTimeout(resolve, 1000))

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
      setIsLoading(false)
    },
  })
  const downloadDocument = () => {
    setIsLoading(true)
    apiUrl
      .post('/app/customer/download-customer-detail', {
        customer_id: customerDetail?.id,
      })
      .then((res) => {
        setFileUrl(res.data, (newFileUrl) => {
          downloadMutation.mutate(newFileUrl)
        })
      })
  }
  const editClientForm = useFormik({
    initialValues: {
      phone_number: '',
      gender: '',
      civil_status: '',
      nationality: 0,
      instruction_grade: 0,
      type_document: '',
      number_document: '',
      type_worker: '',
      occupation: '',
      position: '',
      business_name: '',
      entry_date: 0,
      department_id: 0,
      province_id: 0,
      district_id: 0,
      name: '',
      middlename: '',
      surname: '',
      lastname: '',
      dpto: '',
      urb: '',
      address: '',
      spouse_name: '',
      spouse_middlename: '',
      spouse_surname: '',
      spouse_lastname: '',
      regime_type: '',
      spouse_type_document: '',
      spouse_number_document: '',
      spouse_nationality: 0,
      accept_data_treatment: false,
    },
    validationSchema: customerDetail?.spouse
      ? editCustomerInfoSchema
      : editCustomerInfoSchemaSingle,
    onSubmit: async (values, { resetForm }) => {
      const convertedValue = {
        ...values,
        entry_date: Number(values.entry_date),
        spouse_nationality: Number(values.spouse_nationality),
        nationality: Number(values.nationality),
        instruction_grade: Number(values.instruction_grade),
        department_id: Number(values.department_id),
        province_id: Number(values.province_id),
        district_id: Number(values.district_id),
        civil_status: String(values.civil_status),
        id: customerDetail?.id,
      }
      await apiUrl.patch(`/app/customer/edit-customer-spectrum`, convertedValue)
      customSwal
        .fire({
          icon: 'success',
          title: 'Se ha realizado correctamente',
          allowOutsideClick: false,
        })
        .then(() => {
          $('#EditCustomer').modal('toggle')
          queryClient.invalidateQueries(['client-show', customerDetail!.id])
          queryClient.invalidateQueries(['customer-details', customerDetail!.id])
        })
    },
  })

  const queryClient = useQueryClient()

  const { data: nationalitiesData } = useQuery(
    ['nationalities-list'],
    async () => (await apiUrl.get(`/nationalities/list`)).data
  )

  const { data: CivilStatus } = useQuery(
    ['civil-status'],
    async () => (await apiUrl.get(`/civil-status/list`)).data
  )
  const { data: instructionGradeData } = useQuery(
    ['instruction-grade-list'],
    async () => (await apiUrl.get(`/instruction-grade/list`)).data
  )
  const { data: typeDocumentData } = useQuery(
    ['type-document-list'],
    async () => (await apiUrl.get(`/document-type/list`)).data
  )
  const { data: entryDateData } = useQuery(
    ['entry-date-list'],
    async () => (await apiUrl.get(`/entry-date/list`)).data
  )
  const { data: regimeTypeData } = useQuery(
    ['regime-type-list'],
    async () => (await apiUrl.get(`/regime-type/list`)).data
  )

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 32) {
      e.preventDefault() // Prevent input of space character
    }
  }

  useEffect(() => {
    if (customerDetail) {
      const documentByDescription = typeDocumentData?.data?.find(
        (document: any) => document.description === customerDetail.document_type
      )
      editClientForm.setValues({
        phone_number: customerDetail?.phone_number,
        gender: customerDetail.profile?.gender?.code,
        civil_status: customerDetail.profile?.civil_status?.id,
        nationality: customerDetail.profile?.nationality?.id,
        instruction_grade: customerDetail.profile?.instruction_grade?.id,
        type_document: customerDetail.profile?.document_type?.code || documentByDescription?.code,
        number_document: customerDetail.number_document,
        type_worker: customerDetail.activity?.type_worker?.code,
        occupation: customerDetail.activity?.occupation,
        business_name: customerDetail.activity?.business_name,
        position: customerDetail.activity?.position,
        entry_date: Number(customerDetail.activity?.entry_date?.id) || 0,
        department_id: customerDetail.home?.department_id,
        district_id: customerDetail.home?.district_id,
        province_id: customerDetail.home?.province_id,
        urb: customerDetail.home?.urb,
        address: customerDetail.home?.address,
        dpto: customerDetail.home?.dpto,
        name: customerDetail.name,
        middlename: customerDetail.middlename || '',
        surname: customerDetail.surname || '',
        lastname: customerDetail.lastname,
        spouse_name: customerDetail.spouse?.name,
        spouse_middlename: customerDetail.spouse?.middlename,
        spouse_surname: customerDetail.spouse?.surname,
        spouse_lastname: customerDetail.spouse?.lastname,
        regime_type: customerDetail.spouse?.regime_type_id.toString(),
        spouse_type_document: customerDetail.spouse?.documentType.code,
        spouse_number_document: customerDetail.spouse?.document_number,
        spouse_nationality: customerDetail.spouse?.nationality.id,
        accept_data_treatment: customerDetail.accept_data_treatment,
      })
    }
  }, [customerDetail, typeDocumentData])

  const { data: departmentData } = useQuery(
    ['department-list'],
    async () => (await apiUrl.get(`/departments/list`)).data
  )

  const { data: provinceData } = useQuery(
    ['province-list', editClientForm.values.department_id],
    async () => (await apiUrl.get(`/provinces/list/${editClientForm.values.department_id}`)).data,

    {
      enabled: !!editClientForm.values.department_id,
    }
  )
  const { data: districtData } = useQuery(
    ['district-list', editClientForm.values.province_id],
    async () => (await apiUrl.get(`/districts/list/${editClientForm.values.province_id}`)).data,
    {
      enabled: !!editClientForm.values.province_id,
    }
  )

  return (
    <>
      <div className='d-flex justify-content-between gap-5'>
        <div className='card col-sm-5 p-10'>
          <div className='d-flex justify-content-between align-items-center'>
            <h6
              className='text-muted fs-4 d-flex gap-2 align-items-center'
              style={{ color: 'rgb(52, 64, 84)' }}
            >
              Información del usuario
              {!isLoading && (
                <div
                  onClick={() => {
                    $('#EditCustomer').modal('toggle')
                  }}
                  className='iconButton'
                >
                  <i className='bi bi-pencil-square fs-3 m-2'></i>
                </div>
              )}
            </h6>
            {!isLoading && (
              <>
                {downloadMutation.isLoading || isLoadingDownload ? (
                  <div className='text-center text-muted'>
                    <div
                      className='spinner-border'
                      style={{
                        width: '18px',
                        height: '18px',
                      }}
                      role='status'
                    ></div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      if (downloadMutation.isLoading) {
                        return
                      }
                      downloadDocument()
                    }}
                    className='iconButton'
                  >
                    <i className='bi bi-download fs-3 m-2'></i>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='table-responsive mt-10'>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center m-20 p-20'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <NaturalPersonTable customerDetail={customerDetail!} />
            )}
          </div>
        </div>
        <div className='card col-sm-7 flex-grow-1 p-10'>
          <h6 className='text-muted fs-4' style={{ color: 'rgb(52, 64, 84)' }}>
            Cuentas bancarias
          </h6>
          <div className='mt-5'>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center m-20 p-20'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <BankPersonTable customerDetail={customerDetail!} />
            )}
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between mt-5 gap-5'>
        <div className='col-sm-5 d-flex flex-column gap-5'>
          {customerDetail?.spouse && (
            <div className='card p-10 gap-3 d-flex flex-grow-1 flex-column'>
              <h6 className='text-muted fs-4' style={{ color: 'rgb(52, 64, 84)' }}>
                Información del cónyuge
              </h6>
              <div className='table-responsive mt-5'>
                {(isLoading || isFetching) && !isError ? (
                  <div className='text-center m-5 p-10'>
                    <div className='spinner-border' role='status'></div>
                  </div>
                ) : (
                  customerDetail?.spouse && <SpousePersonTable customerDetail={customerDetail!} />
                )}
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-7 d-flex flex-column gap-5'>
          <div className='card p-10 gap-3 d-flex flex-grow-1 flex-column'>
            <h6 className='text-muted fs-4' style={{ color: 'rgb(52, 64, 84)' }}>
              Contratos
            </h6>
            <div className='table-responsive mt-5'>
              {(isLoading || isFetching) && !isError ? (
                <div className='text-center m-5 p-10'>
                  <div className='spinner-border' role='status'></div>
                </div>
              ) : (
                customerDetail?.contracts && (
                  <ContractDocumentTable customerDetail={customerDetail!} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade p-5 rounded-4' tabIndex={-1} id='EditCustomer' role='dialog'>
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <form onSubmit={editClientForm.handleSubmit}>
              <div className='modal-header py-5 mt-2 fw-bolder'>
                <h1 className='text-alert'>Editar cliente</h1>
              </div>

              <div style={{ maxHeight: 500, overflowY: 'auto' }} className='modal-body'>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Primer nombre</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Primer nombre'
                      {...editClientForm.getFieldProps('name')}
                    />
                    {editClientForm.touched.name && editClientForm.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.name}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Segundo nombre</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Segundo nombre'
                      {...editClientForm.getFieldProps('middlename')}
                    />
                    {editClientForm.touched.middlename && editClientForm.errors.middlename && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.middlename}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Primer apellido</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Primer apellido'
                      {...editClientForm.getFieldProps('surname')}
                    />
                    {editClientForm.touched.surname && editClientForm.errors.surname && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.surname}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Segundo apellido</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Segundo apellido'
                      {...editClientForm.getFieldProps('lastname')}
                    />
                    {editClientForm.touched.lastname && editClientForm.errors.lastname && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.lastname}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2'>
                  <label className='text-muted fs-6 mb-2'>Número de teléfono</label>
                  <input
                    className='form-control form-control-solid'
                    type='text'
                    placeholder='Número de teléfono'
                    onKeyDown={handleKeyDown}
                    {...editClientForm.getFieldProps('phone_number')}
                  />
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Sexo</label>
                    <select className='selectForm' {...editClientForm.getFieldProps('gender')}>
                      <option value=''>Seleccione</option>
                      <option value='01'>Masculino</option>
                      <option value='02'>Femenino</option>
                    </select>
                    {editClientForm.touched.gender && editClientForm.errors.gender && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.gender}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Estado civil</label>
                    <select
                      className='selectForm'
                      {...editClientForm.getFieldProps('civil_status')}
                    >
                      <option value=''>Seleccione</option>{' '}
                      {CivilStatus &&
                        CivilStatus?.data.map((civil: any) => (
                          <option value={civil.id}>{civil.description}</option>
                        ))}
                    </select>
                    {editClientForm.touched.civil_status && editClientForm.errors.civil_status && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.civil_status}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Nacionalidad</label>
                    <select className='selectForm' {...editClientForm.getFieldProps('nationality')}>
                      <option value=''>Seleccione</option>
                      {nationalitiesData &&
                        nationalitiesData?.data.map((nationality: any) => (
                          <option value={nationality.id}>{nationality.description}</option>
                        ))}
                    </select>
                    {editClientForm.touched.nationality && editClientForm.errors.nationality && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.nationality}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Grado de instrucción</label>
                    <select
                      className='selectForm'
                      {...editClientForm.getFieldProps('instruction_grade')}
                    >
                      <option value=''>Seleccione</option>
                      {instructionGradeData &&
                        instructionGradeData?.data.map((instruction_grade: any) => (
                          <option value={instruction_grade.id}>
                            {instruction_grade.description}
                          </option>
                        ))}
                    </select>
                    {editClientForm.touched.instruction_grade &&
                      editClientForm.errors.instruction_grade && (
                        <div className='fv-plugins-message-container'>
                          <span className='text-danger' role='alert'>
                            {editClientForm.errors.instruction_grade}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className='mb-2'>
                  <label className='text-muted fs-6 mb-2'>Tipo de documento</label>
                  <select className='selectForm' {...editClientForm.getFieldProps('type_document')}>
                    <option value=''>Seleccione</option>
                    {typeDocumentData &&
                      typeDocumentData?.data.map((document: any) => (
                        <option value={document.code}>{document.description}</option>
                      ))}
                  </select>
                  {editClientForm.touched.type_document && editClientForm.errors.type_document && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {editClientForm.errors.type_document}
                      </span>
                    </div>
                  )}
                </div>
                <div className='mb-2'>
                  <label className='text-muted fs-6 mb-2'>Número de documento</label>
                  <input
                    className='form-control form-control-solid'
                    type='text'
                    placeholder='Número de documento'
                    onKeyDown={handleKeyDown}
                    {...editClientForm.getFieldProps('number_document')}
                    maxLength={
                      editClientForm.values.type_document === SpectrumDocumentType.DNI
                        ? 8
                        : editClientForm.values.type_document === SpectrumDocumentType.RUC
                        ? 11
                        : 20
                    }
                  />
                  {editClientForm.touched.number_document && editClientForm.errors.number_document && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {editClientForm.errors.number_document}
                      </span>
                    </div>
                  )}
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Tipo de trabajo</label>
                    <select className='selectForm' {...editClientForm.getFieldProps('type_worker')}>
                      <option value=''>Seleccione</option>
                      <option value='D'>Dependiente</option>
                      <option value='I'>Independiente</option>
                    </select>
                    {editClientForm.touched.type_worker && editClientForm.errors.type_worker && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.type_worker}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Ocupación</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Ocupación'
                      {...editClientForm.getFieldProps('occupation')}
                    />
                    {editClientForm.touched.occupation && editClientForm.errors.occupation && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.occupation}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Razón social</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Razón social'
                      {...editClientForm.getFieldProps('business_name')}
                    />
                    {editClientForm.touched.business_name && editClientForm.errors.business_name && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.business_name}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Puesto</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Puesto'
                      {...editClientForm.getFieldProps('position')}
                    />
                    {editClientForm.touched.position && editClientForm.errors.position && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.position}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Antigüedad</label>
                    <select className='selectForm' {...editClientForm.getFieldProps('entry_date')}>
                      <option value=''>Seleccione</option>
                      {entryDateData &&
                        entryDateData?.data.map((entry: any) => (
                          <option value={Number(entry.id)}>{entry.description}</option>
                        ))}
                    </select>
                    {editClientForm.touched.entry_date && editClientForm.errors.entry_date && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.entry_date}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Departamento</label>
                    <select
                      className='selectForm'
                      {...editClientForm.getFieldProps('department_id')}
                      onChange={(e) => {
                        editClientForm.setValues({
                          ...editClientForm.values,
                          department_id: Number(e.target.value),
                          province_id: 0,
                          district_id: 0,
                        })
                        queryClient.setQueryData(['province-list'], [])
                        queryClient.setQueryData(['district-list'], [])
                        queryClient.invalidateQueries(['province-list'])
                        queryClient.invalidateQueries(['district-list'])
                      }}
                    >
                      <option value=''>Seleccione</option>
                      {departmentData &&
                        departmentData?.data.map((department: any) => (
                          <option value={department.id}>{department.description}</option>
                        ))}
                    </select>
                    {editClientForm.touched.department_id && editClientForm.errors.department_id && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.department_id}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Provincia</label>
                    <select
                      className='selectForm'
                      {...editClientForm.getFieldProps('province_id')}
                      onChange={(e) => {
                        editClientForm.setValues({
                          ...editClientForm.values,
                          province_id: Number(e.target.value),
                          district_id: 0,
                        })
                        queryClient.setQueryData(['district-list'], [])
                        queryClient.invalidateQueries(['district-list'])
                      }}
                    >
                      <option value=''>Seleccione</option>
                      {provinceData &&
                        provinceData.data &&
                        provinceData?.data.map((province: any) => (
                          <option value={province.id}>{province.description}</option>
                        ))}
                    </select>
                    {editClientForm.touched.province_id && editClientForm.errors.province_id && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.province_id}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Distrito</label>
                    <select className='selectForm' {...editClientForm.getFieldProps('district_id')}>
                      <option value=''>Seleccione</option>
                      {districtData &&
                        districtData.data &&
                        districtData?.data.map((distric: any) => (
                          <option value={distric.id}>{distric.description}</option>
                        ))}
                    </select>
                    {editClientForm.touched.district_id && editClientForm.errors.district_id && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.district_id}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-2 d-flex justify-content-between'>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>Dirección</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='Dirección'
                      {...editClientForm.getFieldProps('address')}
                    />
                    {editClientForm.touched.address && editClientForm.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.address}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%' }}>
                    <label className='text-muted fs-6 mb-2'>N° de departamento</label>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='N° de departamento'
                      {...editClientForm.getFieldProps('dpto')}
                    />
                    {editClientForm.touched.dpto && editClientForm.errors.dpto && (
                      <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>
                          {editClientForm.errors.dpto}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mb-8'>
                  <label className='text-muted fs-6 mb-2'>Urbanización</label>
                  <input
                    className='form-control form-control-solid'
                    type='text'
                    placeholder='Urbanización'
                    {...editClientForm.getFieldProps('urb')}
                  />
                  {editClientForm.touched.urb && editClientForm.errors.urb && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {editClientForm.errors.urb}
                      </span>
                    </div>
                  )}
                </div>
                <div className='mb-2' style={{ width: '45%' }}>
                  <label className='text-muted fs-6'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={editClientForm.getFieldProps('accept_data_treatment')?.value}
                      {...editClientForm.getFieldProps('accept_data_treatment')}
                    />
                    <span className='form-check-label ms-4'>Tratamiento de datos</span>
                  </label>
                  {editClientForm.touched.accept_data_treatment && editClientForm.errors.accept_data_treatment && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {editClientForm.errors.accept_data_treatment}
                      </span>
                    </div>
                  )}
                </div>
                {customerDetail?.spouse && (
                  <>
                    <div className='mb-2 d-flex justify-content-between'>
                      <div style={{ width: '45%' }}>
                        <label className='text-muted fs-6 mb-2'>Primer nombre del cónyuge</label>
                        <input
                          className='form-control form-control-solid'
                          type='text'
                          placeholder='Primer nombre del cónyuge'
                          {...editClientForm.getFieldProps('spouse_name')}
                        />
                        {editClientForm.touched.spouse_name && editClientForm.errors.spouse_name && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                              {editClientForm.errors.spouse_name}
                            </span>
                          </div>
                        )}
                      </div>
                      <div style={{ width: '45%' }}>
                        <label className='text-muted fs-6 mb-2'>Segundo nombre del cónyuge</label>
                        <input
                          className='form-control form-control-solid'
                          type='text'
                          placeholder='Segundo nombre del cónyuge'
                          {...editClientForm.getFieldProps('spouse_middlename')}
                        />
                        {editClientForm.touched.spouse_middlename &&
                          editClientForm.errors.spouse_middlename && (
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {editClientForm.errors.spouse_middlename}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='mb-2 d-flex justify-content-between'>
                      <div style={{ width: '45%' }}>
                        <label className='text-muted fs-6 mb-2'>Primer apellido del cónyuge</label>
                        <input
                          className='form-control form-control-solid'
                          type='text'
                          placeholder='Primer apellido del cónyuge'
                          {...editClientForm.getFieldProps('spouse_surname')}
                        />
                        {editClientForm.touched.spouse_surname &&
                          editClientForm.errors.spouse_surname && (
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {editClientForm.errors.spouse_surname}
                              </span>
                            </div>
                          )}
                      </div>
                      <div style={{ width: '45%' }}>
                        <label className='text-muted fs-6 mb-2'>Segundo apellido del cónyuge</label>
                        <input
                          className='form-control form-control-solid'
                          type='text'
                          placeholder='Segundo apellido del cónyuge'
                          {...editClientForm.getFieldProps('spouse_lastname')}
                        />
                        {editClientForm.touched.spouse_lastname &&
                          editClientForm.errors.spouse_lastname && (
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {editClientForm.errors.spouse_lastname}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='mb-2 d-flex justify-content-between'>
                      <div style={{ width: '45%' }}>
                        <label className='text-muted fs-6 mb-2'>Tipo de régimen</label>
                        <select
                          className='selectForm'
                          {...editClientForm.getFieldProps('regime_type')}
                        >
                          <option value=''>Seleccione</option>
                          {regimeTypeData &&
                            regimeTypeData?.data.map((regimen: any) => (
                              <option value={regimen.id}>{regimen.description}</option>
                            ))}
                        </select>
                        {editClientForm.touched.regime_type && editClientForm.errors.regime_type && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                              {editClientForm.errors.regime_type}
                            </span>
                          </div>
                        )}
                      </div>
                      <div style={{ width: '45%' }}>
                        <label className='text-muted fs-6 mb-2'>Nacionalidad del cónyuge</label>
                        <select
                          className='selectForm'
                          {...editClientForm.getFieldProps('spouse_nationality')}
                        >
                          <option value=''>Seleccione</option>
                          {nationalitiesData &&
                            nationalitiesData?.data.map((nationality: any) => (
                              <option value={nationality.id}>{nationality.description}</option>
                            ))}
                        </select>
                        {editClientForm.touched.spouse_nationality &&
                          editClientForm.errors.spouse_nationality && (
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {editClientForm.errors.spouse_nationality}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='mb-2'>
                      <label className='text-muted fs-6 mb-2'>Tipo de documento del cónyuge</label>
                      <select
                        className='selectForm'
                        {...editClientForm.getFieldProps('spouse_type_document')}
                      >
                        <option value=''>Seleccione</option>
                        {typeDocumentData &&
                          typeDocumentData?.data.map((document: any) => (
                            <option value={document.code}>{document.description}</option>
                          ))}
                      </select>
                      {editClientForm.touched.spouse_type_document &&
                        editClientForm.errors.spouse_type_document && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                              {editClientForm.errors.spouse_type_document}
                            </span>
                          </div>
                        )}
                    </div>
                    <div className='mb-2'>
                      <label className='text-muted fs-6 mb-2'>
                        Número de documento del cónyuge
                      </label>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        placeholder='Número de documento del cóyunge'
                        onKeyDown={handleKeyDown}
                        {...editClientForm.getFieldProps('spouse_number_document')}
                        maxLength={
                          editClientForm.values.type_document === SpectrumDocumentType.DNI
                            ? 8
                            : editClientForm.values.type_document === SpectrumDocumentType.RUC
                            ? 11
                            : 20
                        }
                      />
                      {editClientForm.touched.spouse_number_document &&
                        editClientForm.errors.spouse_number_document && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                              {editClientForm.errors.spouse_number_document}
                            </span>
                          </div>
                        )}
                    </div>
                  </>
                )}
              </div>
              <div className='modal-footer py-2'>
                <div className='text-end'>
                  <button
                    style={{ backgroundColor: '#eee', color: '#969696' }}
                    type='button'
                    id='cancelButton'
                    className='btn btn col m-3'
                    data-bs-dismiss='modal'
                    onClick={() => {
                      if (customerDetail)
                        editClientForm.setValues({
                          phone_number: customerDetail?.phone_number,
                          gender: customerDetail.profile?.gender?.code,
                          civil_status: customerDetail.profile?.civil_status?.code,
                          nationality: customerDetail.profile?.nationality?.id,
                          instruction_grade: customerDetail.profile?.instruction_grade?.id,
                          type_document: customerDetail.profile?.document_type?.code,
                          number_document: customerDetail.number_document,
                          type_worker: customerDetail.activity?.type_worker?.code,
                          occupation: customerDetail.activity?.occupation,
                          business_name: customerDetail.activity?.business_name,
                          position: customerDetail.activity?.position,
                          entry_date: Number(customerDetail.activity?.entry_date?.id) || 0,
                          department_id: customerDetail.home?.department_id,
                          district_id: customerDetail.home?.district_id,
                          province_id: customerDetail.home?.province_id,
                          urb: customerDetail.home?.urb,
                          address: customerDetail.home?.address,
                          dpto: customerDetail.home?.dpto,
                          name: customerDetail.name,
                          middlename: customerDetail.middlename,
                          surname: customerDetail.surname,
                          lastname: customerDetail.lastname,
                          spouse_name: customerDetail.spouse?.name,
                          spouse_middlename: customerDetail.spouse?.middlename,
                          spouse_surname: customerDetail.spouse?.surname,
                          spouse_lastname: customerDetail.spouse?.lastname,
                          regime_type: customerDetail.spouse?.regime_type_id.toString(),
                          spouse_type_document: customerDetail.spouse?.documentType.code,
                          spouse_number_document: customerDetail.spouse?.document_number,
                          spouse_nationality: customerDetail.spouse?.nationality.id,
                          accept_data_treatment: customerDetail.accept_data_treatment,
                        })
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    style={{
                      background: 'rgb(0, 123, 195)',
                      color: 'white',
                    }}
                    disabled={editClientForm.isSubmitting}
                    type='submit'
                    className='btn btn col m-3 fw'
                  >
                    <div className='d-flex gap-3 align-items-center'>Actualizar</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
