import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ActionModal } from '../../../common/components/ActionModal'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import usePermissions from '../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { UserTable } from './components/UserTable'

export const ListUser = () => {
  const [search, setSearch] = useStateCallback('')
  const [selectedUser, setSelectedUser] = useStateCallback<any>({})
  const [changedUser, setChangedUser] = useStateCallback(false)
  const { hasPermission } = usePermissions()

  return (
    <React.Fragment>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0 align-items-center'>
          <div className='text-center position-relative mt-4'>
            <input
              className='form-control'
              type='text'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder='Buscador'
              style={{
                width: '100%',
                minWidth: '320px',
                backgroundColor: '#f9f9f9',
                border: 0,
                height: '40px',
                paddingLeft: 40,
              }}
              name='searchName'
            />
            <i
              className='bi bi-search'
              style={{
                position: 'absolute',
                top: '50%',
                left: 15,
                transform: 'translateY(-50%)',
              }}
            ></i>
          </div>
          {hasPermission(Permissions.USER_PERSON.CREATE) && (
            <div className='text-end mt-4'>
              <Link to={'/user/add'} className='text-end mt-4 m-5'>
                <button
                  style={{ background: 'rgb(0, 123, 195)' }}
                  className='btn text-white cursor-pointer'
                >
                  <img
                    style={{
                      marginRight: '5px',
                    }}
                    src={toAbsoluteUrl('/✅ Web - Mm/Plus.png')}
                    alt='plus'
                    className='w-15px  '
                  />
                  Agregar
                </button>
              </Link>
            </div>
          )}
        </div>

        <ActionModal
          id='Active'
          title='¿Está seguro que desea activar el usuario?'
          negativeAnswer='No, regresar'
          positiveAnswer='Si, activar'
          type='action'
          selectedValue={selectedUser}
          setChangedValue={setChangedUser}
          changedValue={changedUser}
          confirmationTitle='El usuario fue activado'
          updateStatusUrl='/user/person/change-status/'
        />

        <ActionModal
          id='Inactive'
          title='¿Está seguro que desea inactivar el usuario?'
          negativeAnswer='No, regresar'
          positiveAnswer='Si, inactivar'
          type='action'
          selectedValue={selectedUser}
          setChangedValue={setChangedUser}
          changedValue={changedUser}
          confirmationTitle='El usuario fue inactivado'
          updateStatusUrl='/user/person/change-status/'
        />

        <ActionModal
          id='Archive'
          title='¿Está seguro que desea archivar el usuario?'
          negativeAnswer='No, regresar'
          positiveAnswer='Si, archivar'
          type='action'
          selectedValue={selectedUser}
          setChangedValue={setChangedUser}
          changedValue={changedUser}
          archive={true}
          confirmationTitle='El usuario fue archivado'
          deleteUrl='/user/person/'
        />
        {/* <FundsModal
          title='Fondos invertidos'
          UserId={selectedUser.id}
          id='Funds' /> */}
        <UserTable
          setSelectedUser={setSelectedUser}
          changedUser={changedUser}
          setSearch={setSearch}
          search={search}
        />
      </div>
    </React.Fragment>
  )
}
