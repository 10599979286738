import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { ListLogs } from '../../modules/logger/ListLogs'
import { SuspensedView } from '../PrivateRoutes'

export const LoggerRouting = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <RequireRole roles={Permissions.LOGGER.LIST}>
            <SuspensedView>
              <ListLogs />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
