import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { AddUser } from '../../modules/config/users/AddUser'
import { BusinessExecutive } from '../../modules/config/users/components/businessExecutive/BusinessExecutive'
import { EditUser } from '../../modules/config/users/EditUser'
import { ListUser } from '../../modules/config/users/ListUser'
import { ViewUser } from '../../modules/config/users/ViewUser'
import { SuspensedView } from '../PrivateRoutes'

export const UserRouting = () => {
  return (
    <Routes>
      <Route
        path={`/list`}
        element={
          <RequireRole roles={Permissions.USER_PERSON.LIST}>
            <SuspensedView>
              <ListUser />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path={`/businessExecutive`}
        element={
          <RequireRole roles={Object.values(Permissions.USER_EXECUTIVE)}>
            <SuspensedView>
              <BusinessExecutive />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path={`/add`}
        element={
          <RequireRole roles={Permissions.USER_PERSON.CREATE}>
            <SuspensedView>
              <AddUser />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path={`/edit/:id`}
        element={
          <RequireRole roles={Permissions.USER_PERSON.UPDATE}>
            <SuspensedView>
              <EditUser />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path={`/show/:id`}
        element={
          <RequireRole roles={Permissions.USER_PERSON.SHOW}>
            <SuspensedView>
              <ViewUser />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
