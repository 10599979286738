import { useMutation } from '@tanstack/react-query'
import React from 'react'
import SearchInputWithDelete from '../../../common/components/SearchIconWithDelete'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import { downloadFile, downloadTypes } from '../../../common/helpers/downloadHandlers'
import { useDebounce } from '../../../common/hooks/useDebounce'
import usePermissions from '../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { IAuditFilter } from '../../../common/interfaces'
import { AuditService } from '../../../services'
import { AuditTable } from './components/AuditTable'
import { FilterAudit } from './components/FilterAudit'

export const ListAudit = () => {
  const [search, setSearch] = useStateCallback('')
  const debouncedSearch = useDebounce(search, 500)
  const [filters, setFilters] = useStateCallback<IAuditFilter>({})
  const { hasPermission } = usePermissions()

  const mutation = useMutation({
    mutationFn: () => AuditService().exportAudit(filters),
    onSuccess: (res) => {
      downloadFile(res, downloadTypes.XLSX, `auditoria`)
    },
  })

  return (
    <React.Fragment>
      <div className='card border-0 pt-5 border-radius-20px m-5 m-lg-0'>
        <div className='card-header border-bottom-0 align-items-center'>
          <div className='d-flex align-items-center justify-content-between gap-5 w-100 w-sm-auto'>
            <SearchInputWithDelete
              search={search}
              setSearch={setSearch}
              placeholder='Buscar auditoría'
            />
            {!(Object.keys(filters).length === 0 && filters.constructor === Object) && (
              <span
                style={{ borderBottom: '1px inset #a1a5b7' }}
                className='text-muted cursor-pointer d-block fs-6'
                onClick={() => setFilters({})}
              >
                Limpiar filtros
              </span>
            )}
            <div
              style={{ background: 'rgb(0, 123, 195)' }}
              className='rounded p-2 d-flex justify-content-center align-items-center cursor-pointer'
              data-bs-toggle='modal'
              data-bs-target='#filter-audit'
            >
              <i className='bi bi-filter fs-2 text-white' />
            </div>
          </div>
          {hasPermission(Permissions.AUDIT.EXPORT) && (
            <button
              onClick={() => mutation.mutate()}
              style={{ background: 'rgb(0, 123, 195)' }}
              className='btn text-white cursor-pointer d-flex align-items-center gap-2'
              disabled={mutation.isLoading}
              type='button'
            >
              {mutation.isLoading ? (
                <div className='spinner-border spinner-border-sm' role='status' />
              ) : (
                <i className='bi bi-download text-white fs-5 p-0' />
              )}
              Exportar
            </button>
          )}
        </div>
        <AuditTable
          search={debouncedSearch!}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <FilterAudit filters={filters} setFilters={setFilters} />
    </React.Fragment>
  )
}
