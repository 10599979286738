export interface IAudit {
  id: number
  user: string
  event_type: string
  entity: string
  entity_id: string
  status: AuditStatusEnum
  created_at: string
  updated_at: string
}

export interface IAuditShow extends IAudit {
  user_agent: string
  ip: string
  old_data: any
  new_data: any
}

export interface IAuditFilter {
  entity_id?: string
  event_type?: AuditEventTypeEnum
  entity?: AuditEntityEnum
  user?: string
  created_at_start?: string
  created_at_end?: string
  status?: AuditStatusEnum
}

export enum AuditStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum AuditEventTypeEnum {
  CREATE = 'Creación',
  UPDATE = 'Actualización',
  DELETE = 'Eliminación',
  LOGIN = 'Inicio de sesión',
  RESET_PASSWORD = 'Reseteo de contraseña',
  // CHANGE_PASSWORD = 'Cambio de contraseña',
  BLOCK_USER = 'Bloqueo de usuario',
  UNBLOCK_USER = 'Desbloqueo de usuario',
  LIST = 'Listado',
  EXPORT = 'Exportación',
  SHOW = 'Vizualización',
  SYNC = 'Sincronización',
  IMPORT = 'Importación',
  SEND_SPECTRUM = 'Envio a Spectrum',
}

export enum AuditEntityEnum {
  USER = 'Usuario',
  CUSTOMER = 'Cliente',
  SUBSCRIPTION = 'Suscripción',
  RESCUE = 'Rescate',
  FUND = 'Fondo',
  ROLE = 'Rol',
  EXECUTIVE = 'Ejecutivo',
  CONFIG = 'Configuración',
  LOGGER = 'Logger',
  // AUDIT = 'Auditoria',
}