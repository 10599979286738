export enum downloadTypes {
  PDF = 'application/pdf',
  CSV = 'text/csv',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JSON = 'application/json',
  ZIP = 'application/zip',
  XML = 'application/xml',
  HTML = 'text/html',
  TEXT = 'text/plain',
  IMAGE = 'image/*',
}

function getFileNameFromBucketPath(path: string) {
  return path.substring(path.lastIndexOf('/') + 1, path.length)
}

export const downloadFile = async (data: BlobPart, type: downloadTypes, fileNameRaw: string) => {
  const url = window.URL.createObjectURL(new Blob([data], { type }))
  const link = document.createElement('a')
  link.href = url
  const fileName = getFileNameFromBucketPath(fileNameRaw)
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()

  await new Promise((resolve) => setTimeout(resolve, 1000))

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}
