import { IPayment } from './payment.interface'
import { ISerie } from './serie.interface'

export interface ISubscription {
  id: number
  fund_id: number
  amount: number
  terms_and_conditions: boolean
  customer_id: string
  customer: ISubscriptionCustomer
  status: SuscriptionStatusEnum
  collector_account_prudential: any
  created_at: string
  serie: ISerie
  constancy_date: string
  payments: IPayment[]
  moneda: string
  fund: any
  payment_method: PaymentMethodEnum
  type: string
  payment_token?: string
  internal_transfer: boolean
}

export type TSubscriptionStatus = 'in progress' | 'nulled' | 'complete' | 'pending'

export enum SuscriptionStatusEnum {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  APPROVED = 'APPROVED',
  ANNULLED = 'ANNULLED',
}
export enum RescueStatusEnum {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  APPROVED = 'APPROVED',
  ANNULLED = 'ANNULLED',
}
export enum MoneyTypeEnum {
  // TODO: mapear a PEN y USD
  PEN = 'Soles',
  USD = 'Dólares',
}

export interface ISubscriptionCustomer {
  id: number
  name: string
  lastname: string
  email: string
  middlename: string
  surname: string
}

export interface ISuscription {
  data: ISubscription[]
  limit: number
  page: number
  total: number
}

interface IFund {
  id: number
  codFund: string
  title: string
}

export interface IRescueDetailResponse {
  data: IRescueDetail[]
}
export interface IRescueDetail {
  id: number
  rescue_id: number
  certificate_number: string
  request_number: string
  amount: number
  status: RescueStatusEnum
}

interface IBankAccount {
  id: number
  bank_id: number
  account_type: string
  account_number: string
  cci: string
}

export interface IRescue {
  id: number
  subtotal: number
  commission: number
  total: number
  customer_id: string
  fund: IFund
  details_ids: number[]
  rescue_details: IRescueDetail[]
  serie: ISerie
  bank_account: IBankAccount
  customer: ISubscriptionCustomer
  money_type: MoneyTypeEnum
  status: RescueStatusEnum
  created_at: string
  user_account_json: UserAccountJson
  request_type: string
  internal_transfer: boolean
  constancy_date: string
}

export interface UserAccountJson {
  customer: Customer
  bank_account: IUserBankAccount
}

export interface Customer {
  id: string
  name: string
  lastname: string
  email: string
  phone_number: string
}

export interface IUserBankAccount {
  id: number
  cci: string
  bank_name: string
  money_type: string
  account_number: string
  account_type_id: number
}

export type ISelectedRescue = Partial<Pick<IRescue, 'id' | 'details_ids' | 'status'>>

export enum PaymentMethodEnum {
  TRANSFER = 'TRANSFER',
  ONLINE = 'ONLINE',
}

export const PaymentMethodMap = {
  [PaymentMethodEnum.TRANSFER]: 'Tradicional',
  [PaymentMethodEnum.ONLINE]: 'FLOID',
}
