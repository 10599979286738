import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect } from 'react'
import * as XLSX from 'xlsx'
import { dateRangePickerDefault, getDDMMYYYYHHMMFormatUTC } from '../../../common/helpers/DateHandlers'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { ISuscription, SuscriptionStatusEnum } from '../../../common/interfaces/movement.interface'
import { customSwal } from '../../../config/swal'
import { FundService } from '../../../services/FundService'
import { SuscriptionService } from '../../../services/SuscriptionService'
import { IFilters } from './tabs/SuscriptionTab'

export const Export = () => {
  const [localFilters, setLocalFilters] = useStateCallback<IFilters>({
    start_date: '',
    end_date: '',
  })

  const { data: funds } = useQuery(['fund-show'], () => FundService().showFunds(), {
    keepPreviousData: true,
  })

  const exportMutation = useMutation({
    mutationFn: (localFilters: IFilters) => SuscriptionService().getRangeData(localFilters),
    onSuccess: (res: ISuscription) => {
      if (res.data.length === 0) {
        document.getElementById('cancelButton')?.click()
        customSwal.fire({
          icon: 'error',
          title: 'No se encontraron suscripciones para ese rango de fecha.',
        })
        return
      }
      const convertedData = res.data.map((e) => {
        return {
          'FECHA Y HORA': getDDMMYYYYHHMMFormatUTC(e.created_at),
          CLIENTE:
            e.customer.name.trim() +
            ' ' +
            e.customer.middlename.trim() +
            ' ' +
            e.customer.surname.trim() +
            ' ' +
            e.customer.lastname.trim(),
          FONDO: funds.find((fund: any) => fund.id === e.fund_id).title,
          ESTADO:
            e.status === SuscriptionStatusEnum.INPROGRESS
              ? 'En proceso'
              : e.status === SuscriptionStatusEnum.APPROVED
              ? 'Aprobado'
              : e.status === SuscriptionStatusEnum.PENDING
              ? 'Pendiente'
              : 'Anulado',
          'N° DE OPERACIÓN': e.payments
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .at(0)?.operation_number,
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(convertedData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `SC ${localFilters.start_date.substring(0, 10)} - ${localFilters.end_date.substring(
          0,
          10
        )}.xlsx`
      )

      XLSX.writeFile(
        workbook,
        `SC ${localFilters.start_date.substring(0, 10)} - ${localFilters.end_date.substring(
          0,
          10
        )}.xlsx`
      )
      setLocalFilters({
        start_date: '',
        end_date: '',
      })
    },
  })

  useEffect(() => {
    //@ts-ignore
    $(function () {
      //@ts-ignore
      $('input[name="export_range"]').daterangepicker(dateRangePickerDefault)
      $('input[name="export_range"]').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(
          picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
        )
        setLocalFilters({
          ...localFilters,
          start_date: moment(picker.startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
          end_date: moment(picker.endDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
        })
      })

      $('input[name="export_range"]').on('cancel.daterangepicker', function () {
        $(this).val('')
        setLocalFilters({
          ...localFilters,
          start_date: '',
          end_date: '',
        })
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='modal fade p-5 rounded-4' tabIndex={-1} id='export-suscription' role='dialog'>
      <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header py-5 mt-2 fw-bolder'>
            <h1 className='text-alert'>Exportar suscripciones</h1>
          </div>

          <div className='modal-body'>
            <label className='text-muted fs-6 mb-2'>Seleccione un periodo</label>
            <input
              className='form-control form-control-solid mb-20'
              type='text'
              name='export_range'
              placeholder='Seleccione un periodo'
            />
          </div>
          <div className='modal-footer py-2'>
            <div className='text-end'>
              <button
                style={{ backgroundColor: '#eee', color: '#969696' }}
                type='button'
                id='cancelButton'
                className='btn btn col m-3'
                data-bs-dismiss='modal'
              >
                Cancelar
              </button>
              <button
                style={{
                  background: 'rgb(0, 123, 195)',
                  color: 'white',
                }}
                type='button'
                className='btn btn col m-3 fw'
                disabled={exportMutation.isLoading}
                onClick={() => exportMutation.mutate(localFilters)}
              >
                <div className='d-flex gap-3 align-items-center'>
                  {exportMutation.isLoading && (
                    <div className='text-center align-items-center d-flex'>
                      <div
                        className='spinner-border'
                        style={{ width: 17, height: 17 }}
                        role='status'
                      ></div>
                    </div>
                  )}
                  {exportMutation.isLoading ? 'Confirmando' : 'Confirmar'}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
