/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import FsLightbox from 'fslightbox-react'
import * as React from 'react'
import { FC, useEffect } from 'react'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { formatDateTimeUTC, formatTimeToHHMMSS } from '../../../../common/helpers/DateHandlers'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import usePermissions from '../../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import {
  ISubscription,
  MoneyTypeEnum,
  PaymentMethodEnum,
  PaymentMethodMap,
  SuscriptionStatusEnum,
} from '../../../../common/interfaces/movement.interface'
import { customSwal } from '../../../../config/swal'
import { PaymentService } from '../../../../services'
import { SuscriptionService } from '../../../../services/SuscriptionService'
import { formatCustomerFulllname } from '../../../clients/helpers/customerFormatHelper'
import { useSuscriptions } from '../../hooks/useSuscriptions'
import { PaymentsModal } from '../modals/PaymentsModal'
import { IFilters } from '../tabs/SuscriptionTab'

interface IProps {
  search: string
  setSearch: any
  selectedSuscriptions: number[]
  setSelectedSuscriptions: any
  setSelectedSolicitudes: any
  filters: IFilters
  setFilters: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
  mutationSuccess: boolean
}

const pageSizes = [10, 20, 40, 100]
const MAX_SELECTED = 3

export const SolicitudesTable: FC<IProps> = ({
  search,
  setSearch,
  selectedSuscriptions,
  setSelectedSuscriptions,
  filters,
  setFilters,
  mutationSuccess,
  setSelectedSolicitudes,
}) => {
  const [openPreview, setOpenPreview] = useStateCallback<boolean>(false)
  const [selectedFund, setSelectedFund] = useStateCallback<number>(0)
  const { hasPermission } = usePermissions()
  const { selectedSuscription, setSelectedSuscription, downloadMutation, previewImage } =
    useSuscriptions()
  const formik = useFormik({
    initialValues: {
      collector_account_id: 0,
    },
    onSubmit: (values) => {},
  })
  const updateSubscriptionMutation = useMutation({
    mutationFn: () =>
      SuscriptionService().updateSubscription({
        subscription_id: selectedSuscription,
        collector_account_id: Number(formik.values.collector_account_id),
      }),
    onSuccess: () => {
      customSwal.fire({
        icon: 'success',
        title: `Solicitud de suscripción actualizada.`,
      })
      setSelectedFund(0)
      formik.resetForm()
    },
  })
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: {
      data: subscriptions,
      refetch,
      isRefetching,
      isLoading: isLoadingSuscriptions,
      isFetching,
      isError,
    },
  } = usePaginator({
    initPage: 1,
    initLimit: 10,
    search,
    pageSizes,
    paginatorOptions: {
      queryKey: ['solicitudes-list'],
      fetch: SuscriptionService().listSubscription,
    },
    queryOptions: {
      retry: false,
    },
    filters,
  })

  const { data: banks, isLoading } = useQuery(
    ['banks-list', selectedFund],
    () => PaymentService().getBanks(selectedFund),
    {
      enabled: !!selectedFund,
    }
  )

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), mutationSuccess, search, updateSubscriptionMutation.isSuccess])

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('')
    setFilters(
      {
        start_date: '',
        end_date: '',
        sended_spectrum: false,
      },
      () => {
        refetch()
      }
    )
  }

  const handleClickCheckbox = (id: number) => {
    const subscriptionsFiltered = selectedSuscriptions.filter((item) => item !== id)

    if (subscriptionsFiltered.length >= MAX_SELECTED) {
      customSwal.fire({
        icon: 'warning',
        title: `Solo puede seleccionar un máximo de ${MAX_SELECTED} suscripciones pendientes.`,
      })
      return
    }

    if (!selectedSuscriptions.includes(id)) {
      setSelectedSuscriptions([...selectedSuscriptions, id])
      return
    }

    setSelectedSuscriptions(subscriptionsFiltered)
  }

  return (
    <React.Fragment>
      <div className='card-body'>
        <div className='table-responsive'>
          {((isLoadingSuscriptions || isFetching) && !isError) || isRefetching ? (
            <div className='text-center m-5 p-10'>
              <div className='spinner-border' role='status'></div>
            </div>
          ) : (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  {hasPermission([
                    Permissions.REQUEST_PENDING.CANCEL,
                    Permissions.REQUEST_PENDING.SPECTRUM,
                  ]) && <th className='w-75px'></th>}
                  <th className='w-150px'>FECHA Y HORA</th>
                  <th className='min-w-200px'>CLIENTE</th>
                  <th className='min-w-150px'>FONDO</th>
                  <th className='min-w-150px'>MONTO</th>
                  <th className='w-150px'>N° DE OPERACIÓN</th>
                  <th className='min-w-150px'>BANCO</th>
                  <th className='min-w-150px'>MÉTODO DE PAGO</th>
                  <th className='min-w-100px'>ESTADO</th>
                  {hasPermission([
                    Permissions.REQUEST_PENDING.SHOW,
                    Permissions.REQUEST_PENDING.UPDATE,
                  ]) && <th className='min-w-100px text-center'>ACCIONES</th>}
                </tr>
              </thead>

              <tbody>
                {subscriptions?.data &&
                  subscriptions
                    ?.data!.sort((a: ISubscription, b: ISubscription) =>
                      a.constancy_date && b.constancy_date
                        ? new Date(b.constancy_date).getTime() -
                          new Date(a.constancy_date).getTime()
                        : new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                    )
                    .map((subscription: ISubscription, index: number) => {
                      const newDate = subscription.constancy_date? new Date(subscription.constancy_date): new Date(subscription.created_at)
                      return (
                        <tr key={index}>
                          {hasPermission([
                            Permissions.REQUEST_PENDING.CANCEL,
                            Permissions.REQUEST_PENDING.SPECTRUM,
                          ]) && (
                            <td>
                              {subscription.status === SuscriptionStatusEnum.INPROGRESS &&
                                !!subscription.payments.length && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      paddingTop: '15px',
                                      paddingBottom: '15px',
                                    }}
                                    className='form-check form-check-custom form-check-solid'
                                  >
                                    <input
                                      style={{
                                        width: '24px',
                                        height: '24px',
                                        cursor: 'pointer',
                                      }}
                                      className='form-check-input'
                                      type='checkbox'
                                      id='flexCheckDefault'
                                      checked={selectedSuscriptions.includes(
                                        subscription.payments[0].id
                                      )}
                                      onChange={() => {
                                        handleClickCheckbox(subscription.payments[0].id)
                                        setSelectedSolicitudes((prev: number[]) => {
                                          const index = prev.indexOf(subscription.id)
                                          if (index === -1) {
                                            return [...prev, subscription.id]
                                          }
                                          return [...prev.slice(0, index), ...prev.slice(index + 1)]
                                        })
                                      }}
                                    />
                                  </div>
                                )}
                            </td>
                          )}

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatDateTimeUTC(newDate.toDateString())}
                            </span>
                            <span className='text-muted d-block fs-5'>
                              {formatTimeToHHMMSS(newDate)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatCustomerFulllname(subscription.customer)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {subscription.fund.title + ' - ' + subscription.serie.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {subscription.moneda === MoneyTypeEnum.USD
                                ? '$'
                                : subscription.moneda === MoneyTypeEnum.PEN && 'S/.'}
                              {subscription.amount.toFixed(4)}
                            </span>
                          </td>
                          <td>
                            {subscription.payment_method === PaymentMethodEnum.ONLINE &&
                            subscription.payment_token ? (
                              <span className='text-muted  d-block fs-5'>
                                {subscription.payment_token}
                              </span>
                            ) : subscription.payment_method === PaymentMethodEnum.TRANSFER &&
                              subscription.payments.length ? (
                              <>
                                {hasPermission(Permissions.REQUEST_PENDING.SHOW) &&
                                !!subscription.payments.at(0)?.operation_number ? (
                                  <div
                                    data-bs-toggle='modal'
                                    data-bs-target='#modal-payments'
                                    className='d-flex align-items-center gap-3'
                                    onClick={() => setSelectedSuscription(subscription.id)}
                                  >
                                    <span
                                      style={{
                                        borderBottom: '1px inset #a1a5b7',
                                      }}
                                      className='text-muted d-block cursor-pointer fs-5'
                                    >
                                      {subscription.payments.at(0)?.operation_number}
                                    </span>
                                  </div>
                                ) : (
                                  <div className='d-flex align-items-center gap-3'>
                                    <span className='text-muted d-block fs-5'>
                                      {subscription.payments.at(0)?.operation_number}
                                    </span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <span className='text-muted d-block fs-5'>--</span>
                            )}
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {subscription.collector_account_prudential
                                ? subscription.collector_account_prudential.bank.description
                                : '--'}{' '}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {PaymentMethodMap[subscription.payment_method] || '--'}
                            </span>
                          </td>
                          <td>
                            {subscription.status === SuscriptionStatusEnum.INPROGRESS ? (
                              <span className='badge badge-light-primary'>En proceso</span>
                            ) : subscription.status === SuscriptionStatusEnum.APPROVED ? (
                              <span className='badge badge-light-success'>Aprobado</span>
                            ) : subscription.status === SuscriptionStatusEnum.PENDING ? (
                              <span className='badge badge-light-warning'>Pendiente</span>
                            ) : (
                              subscription.status === SuscriptionStatusEnum.ANNULLED && (
                                <span className='badge badge-light-danger'>Anulado</span>
                              )
                            )}
                          </td>
                          {hasPermission([
                            Permissions.REQUEST_PENDING.SHOW,
                            Permissions.REQUEST_PENDING.UPDATE,
                          ]) && (
                            <td className={'text-center'}>
                              {subscription.payment_method === PaymentMethodEnum.ONLINE ? (
                                <span className='text-muted text-center'>--</span>
                              ) : subscription.payment_method === PaymentMethodEnum.TRANSFER &&
                                subscription.payments &&
                                subscription.payments.at(0)?.voucher ? (
                                <div className='d-flex align-items-center'>
                                  {hasPermission(Permissions.REQUEST_PENDING.SHOW) && (
                                    <>
                                      <div
                                        onClick={() => {
                                          setSelectedSuscription(subscription.id)
                                          downloadMutation.mutate(subscription.id)
                                        }}
                                        className='d-flex align-items-center iconButton p-1 justify-content-center cursor-pointer'
                                      >
                                        {downloadMutation.isLoading &&
                                        selectedSuscription === subscription.id ? (
                                          <div className='text-center text-muted'>
                                            <div
                                              className='spinner-border m-2'
                                              style={{ width: 18, height: 18 }}
                                              role='status'
                                            ></div>
                                          </div>
                                        ) : (
                                          <>
                                            <i className='bi bi-download fs-3 m-2'></i>
                                          </>
                                        )}
                                      </div>
                                      <div
                                        className='iconButton'
                                        onClick={() => {
                                          previewImage(
                                            subscription.payments &&
                                              subscription.payments.at(0)?.voucher,
                                            'currentLightbox'
                                          )
                                          setOpenPreview(!openPreview)
                                        }}
                                      >
                                        {' '}
                                        <i className='bi bi-card-image fs-3 m-2'></i>
                                      </div>
                                    </>
                                  )}
                                  {hasPermission(Permissions.REQUEST_PENDING.UPDATE) && (
                                    <div
                                      className='iconButton'
                                      data-bs-toggle='modal'
                                      data-bs-target='#UpdateSuscription'
                                      onClick={() => {
                                        formik.setValues({
                                          collector_account_id:
                                            subscription.collector_account_prudential.id,
                                        })
                                        setSelectedFund(subscription.fund_id)
                                        setSelectedSuscription(subscription.id)
                                      }}
                                    >
                                      {' '}
                                      <i className='bi bi-pencil fs-3 m-2'></i>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <span className='text-muted text-center'>--</span>
                              )}
                            </td>
                          )}
                        </tr>
                      )
                    })}
              </tbody>
            </table>
          )}
          {subscriptions?.data.length === 0 &&
            !(((isLoadingSuscriptions || isFetching) && !isError) || isRefetching) && (
              <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
                No se encontraron resultados{' '}
                <a
                  className='link'
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                  onClick={cleanupRequest}
                >
                  refrescar la tabla
                </a>
              </span>
            )}
        </div>
        <div className='modal fade p-10' tabIndex={-1} id='UpdateSuscription'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content p-5 pt-0'>
              <div className='modal-header'>
                <h3 className='modal-title'>Actualizar suscripción</h3>

                <i
                  className='btn bi bi-x-lg btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></i>
              </div>

              <div className='modal-body'>
                <div className='d-flex flex-column gap-5'>
                  <form onSubmit={formik.handleSubmit} className='d-flex flex-column gap-5'>
                    <div>
                      <label className='form-label m-2 fw-bolder text-dark fs-6 mb-0'>Banco:</label>
                      <div>
                        <select
                          className='form-select'
                          id='collector_account_id'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          value={formik.values.collector_account_id}
                          onChange={formik.handleChange}
                        >
                          {banks?.data?.map((bank: any, index: number) => (
                            <option key={index} value={bank.id}>
                              {bank.bank.description} - {bank.razon_social}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='card-footer text-end m-0 p-0'>
                      <div
                        className='btn m-2'
                        style={{
                          backgroundColor: '#f9f9f9',
                          color: '#a1a5be',
                          marginLeft: '5px',
                        }}
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        Cancelar
                      </div>
                      <button
                        type='submit'
                        className='btn m-2'
                        disabled={isLoading}
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={() => {
                          updateSubscriptionMutation.mutate()
                        }}
                        style={{ background: 'rgb(0, 123, 195)', color: 'white' }}
                      >
                        Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src='' alt='' />
        <FsLightbox toggler={openPreview} sources={[<div id='currentLightbox'></div>]} />
        {paginationComponent()}
        <PaymentsModal refetchSuscriptions={refetch} selectedSuscriptionId={selectedSuscription} />
      </div>
    </React.Fragment>
  )
}
