/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactJson from '@microlink/react-json-view'
import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import { getDDMMYYYYHHMMFormatUTC } from '../../../common/helpers/DateHandlers'
import { downloadFile, downloadTypes } from '../../../common/helpers/downloadHandlers'
import usePermissions from '../../../common/hooks/usePermissions'
import { AuditStatusEnum, IAuditShow } from '../../../common/interfaces'
import { AuditService } from '../../../services'

export const ViewAudit = () => {
  const { id } = useParams()
  const { hasPermission } = usePermissions()

  const {
    data: audit,
    isLoading,
    isFetching,
    isRefetching,
    isError,
    refetch,
  } = useQuery<IAuditShow>({
    queryKey: ['audit-show', id],
    queryFn: () => AuditService().showAudit(id!),
  })

  const mutation = useMutation({
    mutationFn: (id: string) => AuditService().exportAuditById(id),
    onSuccess: (res) => {
      downloadFile(res, downloadTypes.XLSX, `auditoria-${id}`)
    },
  })

  if (isLoading || isFetching || isRefetching) {
    return (
      <div className='card'>
        <div className='text-center m-5 p-10'>
          <div className='spinner-border' role='status' />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className='card notFound flex-column align-items-center'>
        <span className='text-muted fs-5'>No se encontraron resultados</span>
        <a className='link cursor-pointer fs-5' onClick={() => refetch()}>
          volver a cargar
        </a>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className='d-flex flex-column gap-8'>
        <div className='card shadow-xs'>
          <div className='card-body'>
            <div className='d-flex flex-column gap-6'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center gap-6'>
                  <h1 className='m-0 fw-bolder' style={{ color: '#4B5667', fontSize: '28px' }}>
                    {audit?.entity}
                  </h1>
                  {audit?.status === AuditStatusEnum.SUCCESS ? (
                    <span className='badge badge-light-success fs-4'>Exitosa</span>
                  ) : audit?.status === AuditStatusEnum.FAILED ? (
                    <span className='badge badge-light-danger fs-4'>Fallida</span>
                  ) : (
                    <></>
                  )}
                </div>
                {hasPermission(Permissions.AUDIT.EXPORT) && (
                  <button
                    onClick={() => mutation.mutate(id!)}
                    style={{ background: 'rgb(0, 123, 195)' }}
                    className='btn text-white cursor-pointer d-flex align-items-center gap-2'
                    disabled={mutation.isLoading}
                    type='button'
                  >
                    {mutation.isLoading ? (
                      <div className='spinner-border spinner-border-sm' role='status' />
                    ) : (
                      <i className='bi bi-download text-white fs-5 p-0' />
                    )}
                    Exportar
                  </button>
                )}
              </div>
              <div className='d-flex flex-sm-row flex-column  gap-8'>
                <div className='min-w-100px'>
                  <span className='fw-bolder fs-6' style={{ color: '#7F8BA0' }}>
                    Tipo de evento
                  </span>
                  <p className='text-muted m-0 fs-6'>{audit?.event_type}</p>
                </div>
                <div className='min-w-100px'>
                  <span className='fw-bolder fs-6' style={{ color: '#7F8BA0' }}>
                    Fecha y hora
                  </span>
                  <p className='text-muted m-0 fs-6'>
                    {audit?.updated_at
                      ? getDDMMYYYYHHMMFormatUTC(audit.updated_at)
                      : '--'}
                  </p>
                </div>
                {audit?.entity_id && (
                  <div className='min-w-60px'>
                    <span className='fw-bolder fs-6' style={{ color: '#7F8BA0' }}>
                      Id Entidad
                    </span>
                    <p className='text-muted m-0 fs-6'>{audit.entity_id}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='card shadow-xs'>
          <div className='card-body d-flex flex-column gap-10'>
            <div className='d-flex flex-column gap-8'>
              <h1 className='fw-bolder m-0' style={{ color: '#4B5667', fontSize: '24px' }}>
                Datos generales
              </h1>
              <div className='row row-gap-6'>
                <div className='col-md-6'>
                  <span className='fs-6' style={{ color: '#007BC3' }}>
                    Usuario
                  </span>
                  <p className='mb-0 mt-2 fs-6 pe-4' style={{ color: '#4B5667' }}>
                    {audit?.user}
                  </p>
                </div>
                <div className='col-md-6'>
                  <span className='fs-6' style={{ color: '#007BC3' }}>
                    IP
                  </span>
                  <p className='mb-0 mt-2 fs-6 pe-4' style={{ color: '#4B5667' }}>
                    {audit?.ip}
                  </p>
                </div>
                <div className='col-md-6'>
                  <span className='fs-6' style={{ color: '#007BC3' }}>
                    User Agent
                  </span>
                  <p className='mb-0 mt-2 fs-6 pe-4' style={{ color: '#4B5667' }}>
                    {audit?.user_agent}
                  </p>
                </div>
              </div>
            </div>
            {(audit?.new_data || audit?.new_data) && (
              <div className='d-flex flex-column gap-8'>
                <h1 className='fw-bolder m-0' style={{ color: '#4B5667', fontSize: '24px' }}>
                  Detalle del evento
                </h1>
                <div className='row gx-10'>
                  <div className='col-md-6'>
                    <span className='text-muted fw-bolder fs-4'>Antes</span>
                    <div className='border p-6 overflow-auto'>
                      <ReactJson
                        src={audit.old_data ?? {}}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        name={false}
                        collapsed={2}
                        collapseStringsAfterLength={45}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <span className='text-muted fw-bolder fs-4'>Después</span>
                    <div className='border p-6 overflow-auto'>
                      <ReactJson
                        src={audit.new_data ?? {}}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        name={false}
                        collapsed={2}
                        collapseStringsAfterLength={45}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
