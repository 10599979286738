/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Link } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import usePermissions from '../../../common/hooks/usePermissions'
import { QuestionnaireService } from '../../../services/QuestionnaireService'

export interface Data {
  id: number
  formName: string
  created_at: string
  updated_at: string
  status: number
  questions: Question[]
}
export interface Root {
  data: Data[]
}
export interface Question {
  title: string
  id: number
  created_at: string
  updated_at: string
  status: number
  options: Option[]
}

export interface Option {
  title: string
  id: number
  created_at: string
  updated_at: string
  score: number
}

export const ShowForms = () => {
  const { hasPermission } = usePermissions()
  const { data: questionnaireData, isLoading } = useQuery<any>(['questionnaire-show'], () =>
    QuestionnaireService().showQuestionnaire()
  )

  if (isLoading) {
    return (
      <div className='text-center m-5 p-10'>
        <div className='spinner-border' role='status'></div>
      </div>
    )
  }

  if (Object.keys(questionnaireData).length === 0) {
    return (
      <div className='text-center m-5 p-10'>
        <span>No se encontraron datos.</span>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div>
        <div className='container' key={1}>
          <div className='card'>
            <div className='m-5 px-3 row'>
              <div className='col text-start text-muted mt-5'>
                <h3 style={{ color: '#001F45', letterSpacing: '0.4px' }} className=''>
                  Nombre del formulario:
                </h3>
                <div className='form-floating mb-7'>
                  <span className='option-class fs-4' style={{ letterSpacing: '0.4px' }}>
                    {questionnaireData.data.formName}
                  </span>
                </div>
              </div>
              {hasPermission(Permissions.RISK_TOLERANCE.UPDATE) && (
                <div className='col pt-2 d-flex justify-content-end text-end'>
                  {/* <Link to={'/configuration/forms/edit'} style={{ background: '#D53943', color: 'white', border: '1px solid #D53943', height: '48px', fontWeight: '700' }} className="btn  m-3 fs-6">  <i style={{ color: 'white' }} className="bi bi-close fs-7"></i>Desactivar</Link> */}
                  <Link
                    to={'/configuration/forms/edit'}
                    style={{
                      background: '#0066cc',
                      fontWeight: '700',
                      color: 'white',
                      marginRight: '',
                      height: '48px',
                    }}
                    className='btn  m-3 align-items-center gap-2 d-flex fs-6'
                  >
                    {' '}
                    <i
                      style={{ color: 'white', fontWeight: '700' }}
                      className='bi bi-pencil fs-5'
                    ></i>
                    Editar
                  </Link>
                </div>
              )}
            </div>
          </div>
          {(questionnaireData.data.questions as Array<Question>).map(
            (question: Question, questionIndex: number) => (
              <div key={questionIndex} className='card mt-6 mb-5'>
                <div className='m-5 p-2 row'>
                  <div className='col-lg-10'>
                    <h3 className='question-class'>
                      {questionIndex + 1}. {question.title}
                    </h3>
                  </div>

                  {question.options.map((option, i) => (
                    <ul style={{ width: '100%' }} key={i}>
                      <li
                        className='option-class mt-3'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span className='col-9 text-start '>{option.title}</span>
                        <span className='col-3 text-end'>
                          {option.score}
                          {option.score === 1 ? ' punto .' : ' puntos.'}
                        </span>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
