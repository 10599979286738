import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { AddRoleV2 } from '../../modules/config/roles/AddRoleV2'
import { EditRole } from '../../modules/config/roles/EditRole'
import { ListRole } from '../../modules/config/roles/ListRole'
import { ViewRoleV2 } from '../../modules/config/roles/ViewRoleV2'
import { SuspensedView } from '../PrivateRoutes'

export const RoleRouting = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <RequireRole roles={Permissions.ROLE.LIST}>
            <SuspensedView>
              <ListRole />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='add'
        element={
          <RequireRole roles={Permissions.ROLE.CREATE}>
            <SuspensedView>
              <AddRoleV2 />
              {/* <AddRole /> */}
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='show/:id'
        element={
          <RequireRole roles={Permissions.ROLE.SHOW}>
            <SuspensedView>
              <ViewRoleV2 />
              {/* <ViewRole /> */}
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='edit/:id'
        element={
          <RequireRole roles={Permissions.ROLE.UPDATE}>
            <SuspensedView>
              <EditRole />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
