import { useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import { getDDMMYYYYHHMMFormatUTC } from '../../../common/helpers/DateHandlers'
import { getPermissions } from '../../../common/helpers/Permission'
import { usePermissionCheckboxV2 } from '../../../common/hooks/usePermissionCheckboxV2'
import usePermissions from '../../../common/hooks/usePermissions'
import { IRoleView, StatusEnum } from '../../../common/interfaces'
import { RoleService } from '../../../services'

export const ViewRoleV2 = () => {
  const { id } = useParams()
  const { hasPermission } = usePermissions()

  const {
    data: role,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['role-show', id],
    queryFn: () => RoleService().showRole(id!),
    select: ({ data }) => {
      const permissions = getPermissions(data.permissions)
      return {
        ...data,
        permissions,
      } as IRoleView
    },
  })

  const { permissionTable } = usePermissionCheckboxV2({
    mode: 'view',
    defaultData: role?.permissions,
  })

  if (isLoading || isFetching)
    return (
      <div className='card shador-xs'>
        <IsLoading />
      </div>
    )

  return (
    <div className='d-flex flex-column gap-8'>
      <div className='card shadow-xs'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center gap-6'>
              <span className='fw-bolder' style={{ color: '#4B5667', fontSize: '28px' }}>
                {role?.name}
              </span>
              {statusBadgeMap[role?.status ?? 'default']}
            </div>
            <div className='d-flex gap-10'>
              <div className='d-none d-sm-flex flex-column justify-content-center text-muted text-center'>
                <span className='fw-bolder'>Última actualización</span>
                <span>{getDDMMYYYYHHMMFormatUTC(role?.update_at || '')}</span>
              </div>
              {hasPermission(Permissions.ROLE.UPDATE) && role?.editable && (
                <Link
                  to={`/role/edit/${id}`}
                  style={{ background: 'rgb(0, 123, 195)' }}
                  className='btn px-5 text-white cursor-pointer d-flex align-items-center gap-1'
                >
                  <i className='bi bi-pencil text-white' />
                  Editar
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card shadow-xs'>
        <div className='card-body'>
          <div className='row'>
            <h2 className='fw-bolder' style={{ color: '#4B5667' }}>
              Datos generales
            </h2>
            <p className='mt-4 text-muted'>{role?.description}</p>
          </div>
          <div className='row mt-8'>
            <h2 className='fw-bolder' style={{ color: '#4B5667' }}>
              Permisos generales
            </h2>
            <div className='table-responsive p-2'>{permissionTable()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const statusBadgeMap = {
  [StatusEnum.ACTIVATED]: <span className='badge badge-light-success p-2'>Activo</span>,
  [StatusEnum.DISABLED]: <span className='badge badge-light-danger p-2'>Inactivo</span>,
  [StatusEnum.DELETED]: <span className='badge badge-light-warning p-2'>Archivado</span>,
  [StatusEnum.BLOCKED]: <span className='badge badge-light-warning p-2'>Archivado</span>,
  [StatusEnum.SUSPENDED]: <span className='badge badge-light-warning p-2'>Archivado</span>,
  default: <span>-</span>,
}

const IsLoading = () => (
  <div className='text-center m-5 p-10'>
    <div className='spinner-border' role='status'></div>
  </div>
)
